import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IUser} from "../../../../common/models/user.type";
import {ICheckDelete} from "./Users.service";

export interface InitStateAdminUsers {
    usersSearchValue: string,
    perPage: number,
    selectedUser: IUser,
    deleteResponse: ICheckDelete
}

const initialState: InitStateAdminUsers = {
    usersSearchValue: '',
    perPage: 50,
    deleteResponse: {
        can_delete: false,
        has_subordinates: false,
        has_presets: false,
        success: false,
        same_role_users: []
    },
    selectedUser : {} as IUser
}

const usersSlice = createSlice({
    name: 'adminUsers',
    initialState,
    reducers: {
        setDeleteResponse: (state: InitStateAdminUsers, action: PayloadAction<ICheckDelete>) => {
            state.deleteResponse = action.payload;
        },
        setSelectedUser: (state: InitStateAdminUsers, action: PayloadAction<IUser>) => {
            state.selectedUser = action.payload;
        },
        setUsersSearchValue: (state : InitStateAdminUsers, action: PayloadAction<string>) => {
            state.usersSearchValue = action.payload;
        },
        setPerPage: (state:InitStateAdminUsers, action: PayloadAction<number>) => {
            state.perPage = action.payload;
        }
    }
})

export default usersSlice.reducer
export const {
    setUsersSearchValue,
    setPerPage,
    setDeleteResponse,
    setSelectedUser
} = usersSlice.actions