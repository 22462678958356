import {subDays, subMonths, subQuarters} from "date-fns";
import {DatePresetType} from "../models/datePresets";

export const calculateDatePresetPreviousPeriod = (dateRangePreset: DatePresetType, dateFrom: Date, dateTo: Date) => {
    let from = dateFrom || new Date();
    let to = dateTo || new Date();

    if (dateRangePreset === 'today' || dateRangePreset === 'yesterday') {
        from = subDays(new Date(dateFrom), 1)
        to = subDays(new Date(dateTo), 1)

    } else if (dateRangePreset === 'week_to_date'
        || dateRangePreset === 'last_7_days'
        || dateRangePreset === 'last_week') {
        from = subDays(new Date(dateFrom), 7)
        to = subDays(new Date(dateTo), 7)

    } else if (dateRangePreset === 'this_month'
        || dateRangePreset === 'month_to_yesterday'
        || dateRangePreset === 'last_month'
        || dateRangePreset === 'custom') {
        from = subMonths(new Date(dateFrom), 1)
        to = subMonths(new Date(dateTo), 1)

    } else if (dateRangePreset === 'quarter_to_date'
        || dateRangePreset === 'last_quarter') {

        from = subQuarters(new Date(dateFrom), 1)
        to = subQuarters(new Date(dateTo), 1)

    }

    return {
        from,
        to
    }
}