import {createGlobalStyle} from "styled-components";

export default createGlobalStyle`
    .highcharts-tooltip {
        z-index: 99999 !important;

        .hc-tooltip-wrapper {
            position: relative;
            padding: 7px;
            z-index: 1;

            .label-box {
                display: inline-block;
                height: 12px;
                width: 12px;
                border-radius: 2px;
                margin-right: 4px;

                &.first {
                    background: linear-gradient(0deg, #006A97 0%, #00E0FF 100%);
                }

                &.second {
                    background: linear-gradient(0deg, #0D07AE 0%, #9161FD 100%);
                }

                &.first-pacing {
                    border: 1px solid #006A97;
                    background: repeating-linear-gradient(45deg,
                    #006A97,
                    white 1px,
                    white 3px,
                    #006A97 4px);
                }

                &.second-pacing {
                    border: 1px solid #6B4CD9;
                    background: repeating-linear-gradient(45deg,
                    #606dbc,
                    white 1px,
                    white 3px,
                    #0D07AE 4px);
                }
            }
            .label-line{
                display: inline-block;
                height: 2px;
                width: 12px;
                border-radius: 2px;
                margin-right: 4px;
                &.first {
                    background: linear-gradient(19deg, #E03213 22.27%, #FF5638 83.9%);
                }
                &.second {
                    background: linear-gradient(19deg, #E47B00 9.94%, #FF9A00 90.06%);
                }
                &.first-pacing {
                    border: 1px dashed #E03213;
                    background: repeating-linear-gradient(
                            45deg,
                            #006A97,
                            white 1px,
                            white 3px,
                            #006A97 4px
                    );
                }
                &.second-pacing {
                    border: 1px dashed #E47B00;
                    background: repeating-linear-gradient(
                            45deg,
                            #606dbc,
                            white 1px,
                            white 3px,
                            #0D07AE 4px
                    );
                }

            }
            &:before {
                content: "";
                position: absolute;
                height: calc(100% + 16px);
                width: calc(100% + 16px);
                top: -8px;
                left: -8px;
                background: #fafafa;
                z-index: 0;
                border-radius: 8px;
                box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
            }

            .hc-tooltip-inner {
                position: relative;
                z-index: 1;
                .hc-tooltip-header {
                    font-size: 14px;
                    font-family: ${({theme}) => theme.fonts.AvenirRoman};
                    color: ${({theme}) => theme.colors.deepBlue[4]};
                    margin-bottom: 8px;
                    line-height: 19px;
                }
                
                .hc-traffic-type-header {
                    color: red;
                    display: flex;
                    align-items: center;
                    .mark-box{
                        border-radius: 2px;
                        margin-right: 4px;
                        width: 12px;
                        height: 12px;
                    }
                    
                    .title{
                        font-size: 14px;
                        color: ${({theme}) => theme.colors.deepBlue[6]};
                    }
                    
                    .percentage-value{
                        font-size: 14px;
                        color: ${({theme}) => theme.colors.deepBlue[4]};
                        margin-left: 8px;
                    }
                }
                
                .hc-horizontal-separator {
                    margin: 8px 0;
                    height: 1px;
                    width: 100%;
                    background: ${({theme}) => theme.colors.coolGray[3]};
                }
                
                .hc-tooltip-body{
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    font-size: 12px;
                    color: ${({theme}) => theme.colors.deepBlue[5]};
                    .hc-tooltip-item {
                        line-height: 16px;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: row;
                        .hc-tooltip-label {
                            font-family: ${({theme}) => theme.fonts.AvenirHeavy};
                            margin-right: 20px;
                            display: flex;
                            align-items: center;
                        }
                        
                        .hc-tooltip-value {
                            
                        }
                        
                    }
                }
                
                
                .tooltip-value-item {
                    display: flex;
                    align-items: center;
                    font-size: 10px;
                    position: relative;
                    z-index: 1;
                    margin-top: 4px;
                    .marker {
                        border-radius: 2px;
                        background: gray;
                        margin-right: 5px;
                        height: 8px;
                        width: 8px;
                    }
                    strong{
                        font-family: ${({theme}) => theme.fonts.AvenirHeavy};

                    }
                }
            }
        }
        
        
    }
`