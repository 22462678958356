import {apiInstance} from './apiInstance'
import {api} from './api'
import store from "../../store/store";
import {AxiosError, AxiosRequestConfig, InternalAxiosRequestConfig} from "axios";

export const setUpApiInterceptor = (reduxStore: typeof store.getState, pathname : string) => {
    const handleError = async (error: AxiosError) => {
        return Promise.reject(error)
    }

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    apiInstance.interceptors.request.use(
        async (request: InternalAxiosRequestConfig) => {
            const {token} = reduxStore().auth
            for (const key in request.params) {
                if (typeof request.params[key] === 'string') {
                    // Trim the string parameter
                    request.params[key] = request.params[key].trim();
                }
            }

            request.headers.setAuthorization(`Bearer ${token}`)
            request.headers.set("X-Angular-Referrer", [apiBaseUrl, pathname].join(''))

            // return {
            //     ...config,
            //     headers: {
            //         ...config.headers,
            //         'Authorization' : `Bearer ${token}`,
            //         "X-Angular-Referrer" : [apiBaseUrl, pathname].join('')
            //     }
            // }
            return request

        }
    )


    api.interceptors.request.use(
        async (request: InternalAxiosRequestConfig) => {
            for (const key in request.params) {
                if (typeof request.params[key] === 'string') {
                    // Trim the string parameter
                    request.params[key] = request.params[key].trim();
                }
            }

            request.headers.set("X-Angular-Referrer", [apiBaseUrl, pathname].join(''))
            // return {
            //     ...request,
            //     headers : {
            //         ...request.headers,
            //         "X-Angular-Referrer" : [apiBaseUrl, pathname].join('')
            //     }
            // }
            return request

        }
    )

    apiInstance.interceptors.response.use((response) => response, handleError)
}
