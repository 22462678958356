import React from "react";
import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import store from "./store/store";
import {PersistGate} from "redux-persist/integration/react";
import {persistStore} from "redux-persist";
import Theme from './styles/themes/Theme'
import GlobalStyle from './styles/global.style'
import LeapIcons from './styles/icon.style'
import TooltipStyle from './styles/tooltip.style'
import HCTooltipStyle from './styles/hc-tooltips.style'
import DatePickerStyle from './styles/date-picker.style'

const persistor = persistStore(store);
const version = localStorage.getItem('version');

if(!version){
    localStorage.setItem('version', '')
}

if(version !== process.env.REACT_APP_VERSION){
    console.log("new version")
    process.env.REACT_APP_STORAGE_TO_REMOVE && localStorage.removeItem(process.env.REACT_APP_STORAGE_TO_REMOVE)
    // process.env.REACT_APP_STORAGE_TO_REMOVE_2 && localStorage.removeItem(process.env.REACT_APP_STORAGE_TO_REMOVE_2)
    process.env.REACT_APP_VERSION && localStorage.setItem('version', process.env.REACT_APP_VERSION)
}



ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={<div/>} persistor={persistor}>
                <Theme>
                    <LeapIcons />
                    <GlobalStyle/>
                    <TooltipStyle/>
                    <HCTooltipStyle/>
                    <DatePickerStyle/>
                    <BrowserRouter>
                    <App />
                    </BrowserRouter>
                </Theme>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
