import {useQuery, useQueryClient, useMutation, UseQueryResult} from "@tanstack/react-query";
import {apiInstance} from "../services/apiInstance";
import {AxiosResponse} from "axios";
import {useEffect, useState} from "react";

const fetchWebCache = async <T>(userId: string, key: string, signal?: AbortSignal):  Promise<AxiosResponse<T >> => {
    return await apiInstance.get<T>(
        [
            process.env.REACT_APP_API_V1_URL,
            "web-cache"
        ].join("/"),
        {
            params: {
                'key': userId  + ':' + key
            },
            signal,
            transformResponse: (data: string) => {
                try {
                    const response  = JSON.parse(data)
                    return JSON.parse(response.data) as T
                } catch (e) {
                    return null
                }
            }
        })
}

const updateWebCache = async <T>(userId: string, key: string, data: T) => {
    return await apiInstance.post<T>(
        [
            process.env.REACT_APP_API_V1_URL,
            "web-cache"
        ].join("/"),
        {
            data: JSON.stringify(data)
        },
        {
            params: {
                'key': userId  + ':' + key
            }
        }

        )
}

const deleteWebCache = async (userId: string, key: string) => {
    return await apiInstance.post(
        [
            process.env.REACT_APP_API_V1_URL,
            "web-cache"
        ].join("/"),
        {
            "_method": "DELETE"
        },
        {
            params: {
                'key': userId  + ':' + key
            }
        }
    )
}

/**
 * @param {string} userId - The id of the user
 * @param {string} key - The key of the cache
 * @param initialState - The initial state of the cache
 * @return {Array} - The state, the function to set the state and object containing the query and delete cache function
*/
export const useWebCacheState = <T>(userId: string, key: string, initialState: T) : [T, (state: T) => void, {webCacheQuery:  UseQueryResult<AxiosResponse<T, any>, unknown>, deleteCache: () => void}] => {
    const [state, setState] = useState<T>(initialState)
    const queryClient = useQueryClient()


    const queryData = useQuery(
        [userId, key],
        ({signal}) => fetchWebCache<T>(userId, key, signal),
        {
            refetchOnWindowFocus: false
        }
    )

    useEffect(() => {
        if (queryData.data?.data) {
            setState(queryData.data?.data ?? initialState)
        }
    }, [initialState, queryData.data?.data]);


    const mutation = useMutation(
        async (data: T) => {
            void updateWebCache<T>(userId, key, {...data})
        }
    )

    const setNewState = (state: T) => {
        setState(prev => {
            mutation.mutate({...prev, ...state})
            return {
                ...prev,
                ...state
            }
        })
    }

    const deleteCache = async () => {
        queryClient.removeQueries([userId, key])
        await deleteWebCache(userId, key)
    }

    return [
        state,
        setNewState,
        {
            webCacheQuery: queryData,
            deleteCache
        }
        // queryData.isLoading,
        // deleteCache,
        // queryData.isFetched
    ]
}