import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    IDatePicker,
    IDateWithPreset,
    IMetricOptions,
    IPreset
} from "../../../../common/components/customize-report/interfaces";
import {BASIC_PRESET, ColumnsAndFilters} from "./consts";
import {getDatePresetValues} from "../../../../common/utils";
import {PREVIOUS_YEAR, YEAR_TO_DATE} from "../../../../common/models/datePresets";

export interface IInitialState  {
    customize_report_opened: boolean,
    preset: IPreset,
    presetCopy: IPreset,
    activePresetId: number,
    activePresetName: string | null,
    datePicker: IDatePicker
}

const initialState: IInitialState = {
    customize_report_opened: false,
    preset : BASIC_PRESET,
    datePicker: {
        isCompareActive: false,
        dateRange: {
            preset: YEAR_TO_DATE,
            from: getDatePresetValues(YEAR_TO_DATE).dateFrom,
            to: getDatePresetValues(YEAR_TO_DATE).dateTo
        },
        compareDateRange: {
            preset: PREVIOUS_YEAR,
            from: getDatePresetValues(PREVIOUS_YEAR).dateFrom,
            to: getDatePresetValues(PREVIOUS_YEAR).dateTo
        },
    },
    presetCopy : {} as IPreset,
    activePresetId: 0,
    activePresetName: null
}

const dynamicReportSlice = createSlice({
    name: 'marketerDynamicReport',
    initialState,
    reducers: {
        setCustomizeReportOpened: (state: IInitialState, action: PayloadAction<boolean>) => {
            state.customize_report_opened = action.payload
        },
        addColumn: (state : IInitialState, action : PayloadAction<ColumnsAndFilters>) => {
            let index = state.preset.selected_column_ids.findIndex((column) => column === action.payload)
            if (index > -1){
                state.preset.selected_column_ids[index] = action.payload
            } else {
                state.preset.selected_column_ids.push(action.payload)
            }
        },
        addColumns: (state : IInitialState, action : PayloadAction<ColumnsAndFilters[]>) => {
            state.preset.selected_column_ids = [...action.payload]
        },
        removeColumn: (state : IInitialState, action : PayloadAction<ColumnsAndFilters>) => {
            let index = state.preset.selected_column_ids.findIndex((column) => column === action.payload)
            if (index > -1){
                state.preset.selected_column_ids.splice(index, 1 ,  action.payload)
            }
        },
        removeAllColumns : (state : IInitialState) => {
            state.preset.selected_column_ids = []
        },
        setPreset : (state : IInitialState, action : PayloadAction<IPreset>) =>{
            state.preset = {...action.payload}
        },
        setPresetCopy : (state : IInitialState, action : PayloadAction<IPreset>) =>{
            state.presetCopy = {...action.payload}
        },
        resetPreset: (state: IInitialState, action : PayloadAction<IPreset>) => {

        },
        setDefaultPreset : (state : IInitialState)=> {
            state.preset = BASIC_PRESET
        },
        setPresetDateRange : (state : IInitialState, action : PayloadAction<IDateWithPreset>) => {
            state.preset.dateRange = {...action.payload}
        },
        setPresetCompareDateRange : (state : IInitialState, action : PayloadAction<IDateWithPreset>) => {
            state.preset.compareDateRange = {...action.payload}
        },
        setPresetCompareActive : (state : IInitialState, action : PayloadAction<boolean>) => {
            state.preset.isCompareActive = action.payload
        },
        setActivePreset: (state: IInitialState, action: PayloadAction<number>) => {
            state.activePresetId = action.payload
        },
        setActivePresetName: (state: IInitialState, action: PayloadAction<string | null>) => {
            state.activePresetName = action.payload
        },
        setDatePicker: (state: IInitialState, action: PayloadAction<IDatePicker>) => {
            state.datePicker = {...action.payload}
        },
        setDateRange : (state : IInitialState, action : PayloadAction<IDateWithPreset>) => {
            state.datePicker.dateRange = {...action.payload}
        },
        setCompareDateRange : (state : IInitialState, action : PayloadAction<IDateWithPreset>) => {
            state.datePicker.compareDateRange = {...action.payload}
        },
        setCompareActive : (state : IInitialState, action : PayloadAction<boolean>) => {
            state.datePicker.isCompareActive = action.payload
        },
    }
})

export const {
    setCustomizeReportOpened,
    addColumn,
    addColumns,
    removeColumn,
    removeAllColumns,
    setDefaultPreset,
    setPreset,
    setPresetCopy,
    setDateRange,
    setCompareDateRange,
    setCompareActive,
    setActivePreset,
    setActivePresetName,
    setDatePicker
} = dynamicReportSlice.actions

export default dynamicReportSlice.reducer