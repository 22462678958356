import { createSlice } from "@reduxjs/toolkit";

interface AuthState {
    token: string
    user: any,
    wasAdmin?: string,
    fromDashboard?: boolean,
    config?: any,
    redirect?: string,
    fromLinksPage?: boolean,
    linkSelected?: any
}

const initialState = {
    token: "",
    user: {},
    wasAdmin: "",
    config: {},
    fromDashboard: false,
    redirect: "",
    fromLinksPage: false,
    linkSelected: undefined
} as AuthState;

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        getAuth (state, action) {
            state.token = action.payload.token;
            state.user = action.payload.user;
            state.wasAdmin = action.payload.wasAdmin;
            state.config = action.payload.config
            state.fromDashboard = action.payload.fromDashboard;
            state.redirect = action.payload.redirect;
            state.fromLinksPage = action.payload.from_links_page
            state.linkSelected = action.payload.link;
        }
    },
    extraReducers: {}
});

export const tokenStore = (state: any) => state.auth.token;
export const userStore = (state: any) => state.auth.user;
export const wasAdminStore = (state: any) => state.auth.wasAdmin;

export const { getAuth } = authSlice.actions;

export default authSlice.reducer;
