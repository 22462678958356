import {ColumnState, SortChangedEvent} from "ag-grid-community";
import {TSortType} from "../utils/consts";
import {isNullOrUndefined} from "../utils";
import {useState} from "react";

export const useGridSort = (saveTablePreferences?: (state: ColumnState[]) => void) => {
    const [sort, setSort] = useState<{
        sortBy: string,
        orderBy: TSortType
    } | undefined>()
    const onSortChanged = (event: SortChangedEvent) => {
        const columnToSort = event.columnApi.getColumnState().filter(column => column.sort !== null)[0]
        // const direction = event.columnApi.getColumnState().filter(column => column.sort !== null)[0].sort
        // const sort_by = event.columnApi.getColumnState().filter(column => column.sort !== null)[0].colId
        // cancelTokenSource.current?.cancel();
        const direction = columnToSort?.sort as TSortType
        const sort_by = columnToSort?.colId as string
        (!isNullOrUndefined(sort_by) && !isNullOrUndefined(direction)) && setSort({
            sortBy: sort_by,
            orderBy: direction
        })
        // fetchCampaigns()
        saveTablePreferences && saveTablePreferences(event.columnApi.getColumnState())
    }

    return {
        sort,
        onSortChanged
    }
}