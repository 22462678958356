import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface InitState {
    searchValue: string,
    sort: {
        direction: 'desc' | 'asc',
        by: string
    },
    per_page: number,
        
}

const initialState: InitState = {
    searchValue: '',
    sort: {
        direction: "desc",
        by: 'id'
    },
    per_page: 50
}

const mergedPartnersSlice = createSlice({
    name: 'mergedPartners',
    initialState,
    reducers: {
        setSearchValue: (state: InitState, action: PayloadAction<string>) => {
            state.searchValue = action.payload
        },
        setSort: (state: InitState, action:PayloadAction<{
            direction: 'desc' | 'asc',
            by: string
        }>) => {
            state.sort = action.payload
        },
        setPerPage: (state: InitState, action:PayloadAction<number>) => {
            state.per_page = action.payload;
        },
    }
})

export default mergedPartnersSlice.reducer
export const {
    setSearchValue,
    setPerPage,
    setSort
} = mergedPartnersSlice.actions