import {RefObject} from "react";

export const addContentAtInputCaretPosition = (inputRef: RefObject<HTMLInputElement> | null, contentToAdd: string, onChange: (newValue: string) => void) => {

    if(inputRef && inputRef.current){
        if (document.activeElement !== inputRef.current) {
            inputRef.current.focus();
        }

        const { selectionStart, selectionEnd } = inputRef.current;

        const value = inputRef.current.value;
        const updatedValue = value.substring(0, selectionStart ?? 0) + contentToAdd + value.substring(selectionEnd ?? 0);
        inputRef.current.value = updatedValue;

        onChange(updatedValue)


        const caretPosition = (selectionStart ?? 0) + contentToAdd.length;
        inputRef.current.setSelectionRange(caretPosition, caretPosition);
    }

}