const getFormatter = (maximumFractionDigits: number) => {
    return new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: maximumFractionDigits
    })
}

export const formatNumberToPercentage = (value: number, maximumFractionDigits: number = 2): string => {
    if (isNaN(value)) return ''
    const formatter = getFormatter(maximumFractionDigits)
    return formatter.format(value / 100)
}