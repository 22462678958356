import {useEffect, useState} from "react";
import {isValidDate} from "../utils";

export const useDateInput = (input: string) => {
    const [value, setValue] = useState('')
    const [isValid, setIsValid] = useState(true)

    useEffect(() => {
        if(input.length > 10) return
        let currentValue = input

        setIsValid(isValidDate(input))
        setValue(
            currentValue
                .replace(/^(\d\d)(\d)$/g,'$1/$2')
                .replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2')
                .replace(/[^\d\/]/g,'')
        )
    }, [input])

    return {
        value,
        isValid
    }
}