
export const ROOT = "/"
export const EMPTY = ""
export const DASHBOARD = "dashboard"
export const DYNAMIC = "dynamic"
export const ACCOUNT = "account"
export const CONTACTS = "contacts"
export const PASSWORD = "password"
export const TWO_FA = "two-factor-authentication"
export const AGENCY_MARKETERS = "agency-marketers"
export const AUTH = "auth"
export const LOGIN = "login"
export const VERIFIY = "verify"
export const ADD_DEVICE = "add-device"
export const TOOLS = "tools"
export const BANNER = "banner"
export const PAYOUTS = "payouts"
export const SCHEDULED_REPORTS = "scheduled_reports"
export const SCHEDULED_REPORTS_LOG = "scheduled_reports_log"
export const NOTIFICATIONS = "notifications"
export const TAGS = "tags"

export const VISIBILITY_PRESETS = "visibility-presets"
export const FEATURES = "features"
export const TRACKING_PIXELS = "tracking-pixels"
export const EVENTS = "events"
export const AUTOMATED_ADJUSTMENTS = "automated-adjustment"
export const EVENTS_ADJUSTMENT = "events-adjustment"
export const METIS = "metis"

export const REPORTS = "reports"
export const BUDGETS_REPORT = "budget-maximizer"
