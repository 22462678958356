import {createGlobalStyle} from "styled-components";

export default createGlobalStyle`

    .react-tooltip-container {
        background-color: ${({theme}) => theme.colors.pureWhite} !important;
        opacity: 1 !important;
        z-index: 99999 !important;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
        border: 1px solid #E9ECED;
        font-size: 12px !important;
        color: ${({theme}) => theme.colors.deepBlue['5']} !important;
        padding: 16px !important;
        border-radius: 8px !important;
        
        
        em {
            font-style: normal;
            font-family: ${({theme}) => theme.fonts.AvenirItalic};
        }

        .tooltip-list {
            margin-top: 8px;
            //margin-bottom: 8px;
            li {
                list-style-type: disc;
                margin-left: 12px;
                line-height: 1.35;
                overflow-wrap: break-word;
            }
        }
        
        .dynamic-width{
            width: min-content;
        }

        strong {
            font-family: ${({theme}) => theme.fonts.AvenirHeavy} !important;
        }

        &.engine-growth-tooltip {
            font-size: 12px !important;

            > div {
                margin-bottom: -5px;
            }

            .tooltip-pill {
                height: 9px;
                width: 20px;
                border-radius: 9px;

                &-green {
                    background: ${({theme}) => theme.colors.positive[5]};
                }

                &-red {
                    background: ${({theme}) => theme.colors.negative[5]};
                }

                &-gray {
                    background: ${({theme}) => theme.colors.deepBlue[2]};
                }

                &-neutral {
                    border: 1px solid ${({theme}) => theme.colors.deepBlue[3]};
                }
            }
            
            .tooltip-bullet {
                width: 6px;
                height: 6px;
                border-radius: 100%;
                &-green {
                    background: ${({theme}) => theme.colors.positive[5]};
                }
                &-yellow {
                    background: ${({theme}) => theme.colors.warning[5]};
                }
                &-red {
                    background: ${({theme}) => theme.colors.negative[5]};
                }
            }

            .circle-tooltip-value {
                width: 16px;
                height: 16px;
                border-radius: 100%;
                display: block;
                &-partner {
                    width: 12px;
                    height: 12px;
                    border-radius: 100%;
                }
                &-8px {
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                }
            }

            .diamond-tooltip-value {
                width: 12px;
                height: 12px;
                transform: rotate(45deg);

                &-partner {
                    width: 9px;
                    height: 9px;
                    transform: rotate(45deg);
                }
            }

            .icon-group{
                display: flex;
                align-items: center;
                gap: 4px;
            }

            .positive {
                color: ${({theme}) => theme.colors.positive[7]};
            }

            .negative {
                color: ${({theme}) => theme.colors.negative[7]};
            }

            .neutral {
                color: ${({theme}) => theme.colors.deepBlue[5]};
            }


            .arrow {
                &-down {
                    transform: scale(1, -1);
                }

                &-up {
                    transform: scale(-1, 1);
                }

                &-right {
                    transform: rotate(90deg);
                }

                &-left {
                    transform: rotate(180deg);
                }

                &-positive {
                    path {
                        fill: ${({theme}) => theme.colors.positive[5]};
                    }
                }

                &-negative {
                    path {
                        fill: ${({theme}) => theme.colors.negative[5]};
                    }
                }

                &-neutral {
                    path {
                        fill: ${({theme}) => theme.colors.deepBlue[4]};
                    }
                }
            }
            .title {
                font-size: 14px !important;
                font-family: ${({theme}) => theme.fonts.AvenirRoman};
                color: ${({theme}) => theme.colors.deepBlue[4]};
                margin-bottom: 8px;
                white-space: normal;
            }
            .centered {
                text-align: center;
            }

            .partner-budget-tooltip {
                &__title {
                    font-size: 14px !important;
                    font-family: ${({theme}) => theme.fonts.AvenirRoman};
                    color: ${({theme}) => theme.colors.deepBlue[4]};
                    
                }
                
                &__row {
                    margin-top: 8px;
                    display: flex;
                    justify-content: space-between;
                    font-size: 12px;

                }
                
                &__links {
                    border-bottom: 1px solid ${({theme}) => theme.colors.coolGray[3]};
                }
                
                &__label{
                    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
                    color: ${({theme}) => theme.colors.deepBlue[5]};
                    white-space: normal;
                }
                
                &__values {
                    color: ${({theme}) => theme.colors.deepBlue[5]};
                    
                }
                
                &__percentage {
                    &-1 {
                        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
                        color: ${({theme}) => theme.colors.deepBlue[5]};
                    }

                    &-2 {
                        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
                        color: ${({theme}) => theme.colors.juicedOrange[6]};
                    }

                    &-3 {
                        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
                        color: ${({theme}) => theme.colors.negative[7]};
                    }
                }
            }

            .remaining-line {
                width: 3px;
                height: 10px;
                &-green {
                    background: ${({theme}) => theme.colors.positive[4]};
                }
                &-orange {
                    background: ${({theme}) => theme.colors.juicedOrange[4]};
                }
                &-red {
                    background: ${({theme}) => theme.colors.negative[4]};
                }
            }

        }

        .common-tooltip {
            p {
                margin-bottom: 12px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .dest-param-pill-tooltip {
            max-width: 200px;
        }

        .tooltip-row-wrapper {
            display: flex;
            //align-items: center;
            margin-bottom: 8px;
            font-size: 12px;
            align-items: flex-start;
            
            &-space-between {
                justify-content: space-between;
                gap: 40px;
                .left {
                    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
                }
            }
            
            &-centered {
                display: flex;
                align-items: center;
                gap: 8px;
                margin-bottom: 8px;
            }
            
            &-rating {
                display: flex;
                width: 100%;
                justify-content: center;

                i{
                    font-size: 16px;
                    color: ${({theme}) => theme.colors.coolGray[3]};
                    &.filled{
                        color: ${({theme}) => theme.colors.performBlue[5]};
                    }
                }
            
            }
            
            &-last {
                margin-bottom: 0;
            }
            
            &__label {
                text-align: left;
                white-space: nowrap;
            }
            
            &__value {
                text-align: right;
                white-space: nowrap;
            }
            
            .tooltip-difference-value {
                display: flex;
                align-items: center;
                gap: 4px;
                .bullet {
                    width: 6px;
                    height: 6px;
                    border-radius: 100%;
                    background: gray;
                }
                
                &-green {
                    color: ${({theme}) => theme.colors.positive[7]};
                    .bullet {
                        background: ${({theme}) => theme.colors.positive[5]};
                    }
                }
                &-yellow {
                     color: ${({theme}) => theme.colors.warning[7]};
                     .bullet {
                        background: ${({theme}) => theme.colors.warning[5]};
                    }
                }
                &-red {
                     color: ${({theme}) => theme.colors.negative[7]};
                     .bullet {
                        background: ${({theme}) => theme.colors.negative[5]};
                    }
                }
            }
            .tooltip-icon-group {
                display: flex;
                min-width: 32px;
                margin-right: 8px;
                gap: 4px;

                .arrow-positive, .arrow-negative {
                    width: 8px;
                    height: 6px;
                }
                .tooltip-bullet, svg {
                    width: 6px;
                    height: 6px;
                }
            }
            .tooltip-bullet, svg, .tooltip-pill {
                margin-top: 5px;
            }
            
                
        }

        div {
            :nth-last-child(1 of .tooltip-row-wrapper) {
                margin-bottom: 0;
            }
        }
        
        .tooltip-header {
            font-size: 14px;
            color: ${({theme}) => theme.colors.deepBlue[4]};
            
            
            &-centered {
                text-align: center;
            }
        }
        

        .tooltip-separator {
            height: 1px;
            width: 100%;
            margin: 8px 0;
            border: none;
            background: ${({theme}) => theme.colors.coolGray[2]};
        }

        &.invoice-tooltip {
            > div {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }
            .tooltip-row {
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                .label {
                    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
                }
            }
        }
        .pill {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2px 6px;
            border-radius: 20px;
            font-size: 10px;
            font-family: ${({theme}) => theme.fonts.AvenirHeavy};
            color:${({theme}) =>theme.colors.pureWhite};
            background-color:${({theme}) => theme.colors.deepBlue[5]};
            text-transform: uppercase;
            &.pill-gray {
                color:${({theme}) =>theme.colors.pureWhite};
                background-color:${({theme}) => theme.colors.deepBlue[5]};
            }
            &.pill-transparent {
                color: ${({theme}) => theme.colors.deepBlue[1]};
                background-color: transparent;
            }
        }

        
    }
    .dynamic-tooltip {
        background-color: ${({theme}) => theme.colors.pureWhite} !important;
        opacity: 1 !important;
        z-index: 99999 !important;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
        border: 1px solid #E9ECED;
        color: ${({theme}) => theme.colors.deepBlue['5']} !important;
        border-radius: 8px !important;
        font-size: 12px !important;
        max-width: 200px;
        padding: 12px !important;
        color: ${({theme}) => theme.colors.deepBlue['5']};
        &.show {
            opacity: 1;
        }
        &.no-arrow {
            &:after {
                display: none;
            }
        }

        strong {
            font-family: ${({theme}) => theme.fonts.AvenirHeavy};
        }
    }

        .invoice-box{
            width: 12px;
            height: 12px;
            border-radius: 2px;
            margin-right: 2px;
            margin-top: 3px;
            &-no-invoice{
                background: ${({theme}) => theme.colors.coolGray[3]};
            }
            &-posted{
                background: #3479FF;
            }
            &-paid{
                background: ${({theme}) => theme.colors.positive[4]};
            }
            &-overdue{
                background: ${({theme}) => theme.colors.negative[5]};
            }
            &-paid-late{
                background: ${({theme}) => theme.colors.warning[5]};
            }
        }

    &.navigation-tooltip {
        font-size: 12px !important;
        padding: 6px 12px !important;
    }
    
    .budget-link-tooltip{
        //overflow-y: auto !important;
        pointer-events: auto !important;
        &:hover {
            visibility: visible !important;
            opacity: 1 !important;
        }
        //max-height: 200px;
    }

`