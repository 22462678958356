import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilterID } from "filters";
import { PageID } from "./filters.slice";

export interface IFilter<T> {
    id: FilterID
    selected: T[]
    exclude: boolean
    match?: 'any' | 'all' | 'empty'
}

export type IFiltersInitialState<T> = {
    filters:
    {
        pageID: PageID,
        data_from?: string
        date_to?: string
        filters: IFilter<T>[]
    }[]
}

const initialState: IFiltersInitialState<any> = {
    filters: [{
        pageID : 'admin/dynamic',
        filters: []
    }]
}

const dynamicFilterSlice = createSlice({
    name: 'dynamic-filters',
    initialState,
    reducers: {
        setFilter: (
            state: IFiltersInitialState<any>,
            action: PayloadAction<{
                pageID: PageID,
                filter: IFilter<any>
            }>
        ) => {
            if (state.filters.some(filter => filter.pageID === action.payload.pageID)) {
                const pageIndex = state.filters.findIndex(filter => filter.pageID === action.payload.pageID)
                const filterIndex = state.filters[pageIndex].filters.findIndex(filter => filter.id === action.payload.filter.id)
                if (filterIndex === -1) {
                    state.filters[pageIndex].filters.push(action.payload.filter)
                } else {
                    state.filters[pageIndex].filters[filterIndex] = action.payload.filter
                }
            } else {
                state.filters = [
                    ...state.filters,
                    {
                        pageID: action.payload.pageID,
                        filters: [action.payload.filter]
                    }
                ]
            }
        },
        setFilters: (
            state: IFiltersInitialState<any>,
            action: PayloadAction<{
                pageID: PageID,
                filters: IFilter<any>[]
            }>
        ) => {
           action.payload.filters.forEach( filterItem =>{
               if (state.filters.some(filter => filter.pageID === action.payload.pageID)) {
                   const pageIndex = state.filters.findIndex(filter => filter.pageID === action.payload.pageID)
                   const filterIndex = state.filters[pageIndex].filters.findIndex(filter => filter.id === filterItem.id)
                   if (filterIndex === -1) {
                       state.filters[pageIndex].filters.push(filterItem)
                   } else {
                       state.filters[pageIndex].filters[filterIndex] = filterItem
                   }
               } else {
                   state.filters = [
                       ...state.filters,
                       {
                           pageID: action.payload.pageID,
                           filters: [filterItem]
                       }
                   ]
               }
           })
        },

        clearSelected: (state: IFiltersInitialState<any>, action: PayloadAction<{ pageId: PageID, filterId: FilterID }>) => {
            const pageIndex = state.filters.findIndex(filter => filter.pageID === action.payload.pageId)
            const filterIndex = state.filters[pageIndex].filters.findIndex(filter => filter.id === action.payload.filterId)
            if(pageIndex === -1) return
            if(filterIndex === -1) return;
            state.filters[pageIndex].filters[filterIndex].selected = []
        },

        singleSelect: (state: IFiltersInitialState<any>, action: PayloadAction<{ pageId: PageID, filterId: FilterID, data: any }>) => {
            const pageIndex = state.filters.findIndex(filter => filter.pageID === action.payload.pageId)
            const filterIndex = state.filters[pageIndex].filters.findIndex(filter => filter.id === action.payload.filterId)
            state.filters[pageIndex].filters[filterIndex].selected = [action.payload.data]
        },

        addFilter: (state: IFiltersInitialState<any>, action: PayloadAction<{ pageId: PageID, filterId: FilterID }>) => {
            console.log("filter added");
            
            const filter: IFilter<any> = {
                id: action.payload.filterId,
                selected: [],
                exclude: false
            }
            if (state.filters.some(filter => filter.pageID === action.payload.pageId)) {
                const pageIndex = state.filters.findIndex(filter => filter.pageID === action.payload.pageId)
                const filterIndex = state.filters[pageIndex].filters.findIndex(filter => filter.id === action.payload.filterId)
                if (filterIndex === -1) {
                    state.filters[pageIndex].filters.push(filter)
                }
            } else {
                state.filters = [
                    ...state.filters,
                    {
                        pageID: action.payload.pageId,
                        filters: [filter]
                    }
                ]
            }
        },
        
        setFiltersOrder: (state:IFiltersInitialState<any>, action: PayloadAction<{pageId: PageID, filters: IFilter<any>[]}>) => {
            const pageIndex = state.filters.findIndex(filter => filter.pageID === action.payload.pageId)
            state.filters[pageIndex].filters = action.payload.filters
        },

        removeFilter: (state: IFiltersInitialState<any>, action: PayloadAction<{ pageId: PageID, filterId: FilterID }>) => {
            const pageIndex = state.filters.findIndex(filter => filter.pageID === action.payload.pageId)
            if (pageIndex === -1) return
            const filterIndex = state.filters[pageIndex].filters.findIndex(filter => filter.id === action.payload.filterId)
            if (filterIndex === -1) return
            state.filters[pageIndex].filters.splice(filterIndex, 1)
        },

        clearAllFilters: (state: IFiltersInitialState<any>, action: PayloadAction<PageID>) => {
            const pageIndex = state.filters.findIndex(filter => filter.pageID === action.payload)
            let platformFilter = state.filters[pageIndex].filters.find((item:any) => item.id == 'dynamic-platform')
            if (pageIndex !== -1) {
                state.filters[pageIndex].filters = [...state.filters[pageIndex].filters.map(filter => {
                    return {
                        ...filter,
                        selected: [],
                        match: 'any' as 'any',
                        exclude: false
                    }
                })]
            }
        },

        removeAllFilters: (state: IFiltersInitialState<any>, action: PayloadAction<PageID>) => {
            const pageIndex = state.filters.findIndex(filter => filter.pageID === action.payload)
            if (pageIndex !== -1) {
                state.filters[pageIndex].filters = []
            }
        },

        setMatch: (state: IFiltersInitialState<any>, action: PayloadAction<{ match: 'any' | 'all' | 'empty', pageId: PageID, filterId: FilterID }>) => {
            const pageIndex = state.filters.findIndex(filter => filter.pageID === action.payload.pageId)
            if (pageIndex === -1) return
            const filterIndex = state.filters[pageIndex].filters.findIndex(filter => filter.id === action.payload.filterId)
            state.filters[pageIndex].filters[filterIndex].match = action.payload.match
        },
        copyFilters: (state: IFiltersInitialState<any>, action: PayloadAction<{ copyFrom: PageID, copyTo: PageID, excludeFilters?: FilterID[] }>) => {
            const filtersToCopy = state.filters.find((filter) => filter.pageID === action.payload.copyFrom)

            if (!filtersToCopy) return

            const pageIndex = state.filters.findIndex(filter => filter.pageID === action.payload.copyTo)

            if (pageIndex === -1) {
                state.filters.push({
                    pageID: action.payload.copyTo,
                    filters: filtersToCopy.filters
                })
            } else {
                state.filters[pageIndex].filters = filtersToCopy.filters
            }
        }
    },
})

export default dynamicFilterSlice.reducer

export const {
    clearSelected,
    addFilter,
    removeFilter,
    clearAllFilters,
    setMatch,
    copyFilters,
    singleSelect,
    setFiltersOrder,
    setFilters,
    removeAllFilters
} = dynamicFilterSlice.actions

export const setFilter = dynamicFilterSlice.actions.setFilter as <T>(filter: {
    pageID: PageID,
    filter: IFilter<T>
}) => PayloadAction<{
    pageID: PageID,
    filter: IFilter<T>
}>
