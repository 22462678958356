import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ALL} from "../../../../../../common/models/datePresets";
import {IPreset} from "../../../../../../common/components/range-date-picker/presets/presets.props";

export interface IInitialState  {
    date_from: Date | null | undefined,
    date_to: Date | null | undefined,
    date_range_preset: IPreset,
    per_page: number,
}

const initialState : IInitialState = {
    date_from: null,
    date_to: null,
    date_range_preset: {
        value: ALL,
        name: "All"
    },
    per_page: 50,
}

const AutomatedAdjustmentsSlice = createSlice({
    name: 'AutomatedAdjustmentsSlice',
    initialState,
    reducers: {
        setDateRangePreset : (state : IInitialState, action : PayloadAction<IPreset>) => {
            state.date_range_preset = action.payload
        },
        setDateFrom : (state : IInitialState, action : PayloadAction<Date | null | undefined>) => {
            state.date_from = action.payload
        },
        setDateTo : (state : IInitialState, action : PayloadAction<Date | null | undefined>) => {
            state.date_to = action.payload
        }
    }
})

export const {
    setDateRangePreset,
    setDateFrom,
    setDateTo
} = AutomatedAdjustmentsSlice.actions

export default AutomatedAdjustmentsSlice.reducer