import { createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../../../store/store";
import _ from "lodash";
import CreateOfferService from "./CreateOffer.service";
import {
    ICreateOffer,
    IOffer,
    INiche,
    ICustomDomain,
    IFetchPixelGroupsResponse,
    IBudgetGroups,
    IFetchTrafficGroupsResponse,
    IFetchGeoDataResponse,
    IFetchIspListResponse,
    IEventValidate,
    NavState,
    IFetchAccountBalanceResponse,
    navigationList, ICreative, DETAILED_PRICING_MODEL,
} from "./interfaces/interfaces";

import {BUDGET_AMOUNT_TYPES} from "./shared-components/consts";
import {SelectOption} from "../../../../common/components/select/Select";
import {IPagination} from "../../../../common/models/pagination.type";

const targetOrigin = process.env.REACT_APP_API_BASE_URL

const initialState : ICreateOffer = {
    offer : {
        id : 0,
        type: "",
        name: "",
        status: '',
        description: "",
        brand_name: "",
        niche_id: 0,
        niche: null,
        custom_domain: null,
        custom_domain_id: 0,
        domain_id: null,
        trackingSystem: null,
        tracking_system_id: null,
        destination_url: "",
        destination_url_copy: "",
        destination_url_event_macros: "",
        destination_url_ts_macros: "",
        destination_custom_macros: "",
        preview_url: '',
        direct_linking: null,
        legacy_tracking: null,
        domain: '',
        auto_populate_domain: true,
        domain_parameter_id: '',
        mobile_type: "",
        mobile_platform : "",
        landing_page_url: '',
        impression_tracking_url: "",
        impression_tracking_required : false,
        convert_impression: false,
        testing: "",
        testing_link: "",
        testing_ts: false,
        app_url: '',
        play_store_url: '',
        operating_system_id: null,
        upsells: [],
        tracking_code_placed: null,
        budget: 0, // iznos za budzet
        budget_value_type: 0, // dolari ili konverzije
        budget_type: 'unlimited', // tip budzeta
        budget_group_id: null, // id budzet grupe
        budget_group: null,
        orig_budget_value_type: null,
        orig_budget_group_id: null,
        campaign_budget_group_info: null,
        pixel_group: null,
        pixel_group_id: null,
        link_test_budget_type: 'disabled', // tip test budzeta
        link_test_budget_value: 0, // dolari ili konverzije test budzet
        link_test_budget: 0,  //iznos za test budzet
        campaign_tracking_settings: {
            campaign_id: null,
            conversion_page_url: null,
            parallel_tracking_enabled: false,
            click_tracking_url: null,
            tracking_domain: null,
            auto_populate_domain: true,
            tracking_clickid_parameter: null,
            conversion_tracking_url: null,
            click_tracking_url_copy: ''
        },
        cpa: null,
        is_revshare: false,
        revshare: [
            {
                id: _.uniqueId('MAIN_REVSHARE_'),
                name: '',
                value: ''
            }
        ],
        creatives: [],
        additionalCreative: {
            text_creative: ''
        },
        is_email: false,
        traffic_types: null,
        desktop: true,
        mobile: true,
        tablet: true,
        os_targeting: {
            tablet: {
                android: 1,
                ios: 1,
                windows: 1,
                mac: 1,
                linux: 1,
                undefined: 1
            },
            mobile: {
                android: 1,
                ios: 1,
                windows: 1,
                mac: 1,
                linux: 1,
                undefined: 1
            }
        },
        targeted_locations: [],
        excluded_locations: [],
        selected_cities: [],
        cities_type: 'all',
        selected_isp: [],
        isp_type: 'all',
        selected_zipcodes: [],
        zipcode_files: [],
        zipcode_type: 'all',
        selected_zipcode: '',
        start_date_option: 0,
        end_date_option: 0,
        start_date: '',
        end_date: '',
        campaign_schedule: [],
        pricing_model : null,
        detailed_pricing_model: null,
        payout_modifiers: [],
        max_bid: "",
        campaign_email_setting: {
            id: 0,
            campaign_id: null,
            from_lines: '',
            from_lines_aff: '',
            subject_lines: '',
            subject_lines_aff: '',
            unsubscribe_type: 'url',
            unsubscribe_type_aff: 'url',
            unsubscribe_type_value: '',
            unsubscribe_type_value_aff: '',
            supression_list: 'optizmo',
            supression_list_value1: "",
            supression_list_value2: "",
            suppression_list_aff: "",
            suppression_list_value1_aff: "",
            suppression_list_value2_aff: ""
        },
        delayedActions: {
            'offer-info' :null,
            'targeting': null,
            'tracking-system' :null,
            'land-and-track' : null,
            'outcome-funnel': null,
            'tracking-codes': null,
            'testing': null,
            'creatives-and-email': null,
            'budget': null,
            'scheduling': null
        }
    },
    emptyOffer: {},
    showBack: false,
    showExit : true,
    nextPage: "",
    prevPage: "",
    niches: [],
    customDomains: [],
    appsSelected: false,
    budgetGroups: [
        ],
    geoData: {
        loading: false,
        error: null,
        data: {
            cities: null,
            continents: null,
            countriesState: null,
            regions: null
        }
    },
    isps: {
        loading: false,
        error: null,
        data: {
            isps: []
        }
    },
    advancedTargetingExpanded: false,
    advancedSchedulingExpanded: false,
    createOfferState: {
        loading: false,
        error: null
    },
    accountBalance: null,
    fieldValidation: "",
    validation: {
        validateOfferName: undefined,
        validateConversionPage: undefined,
        validateOfferTrackingUrl: undefined,
        validateEvent: {},
        validateEventPostbackUrl: {},
        validateLandingPageUrl: undefined,
        validateAppOrGoogleStoreUrl : undefined,
        validateConversionPostbackUrl : undefined,
        validatePreviewUrl: undefined,
        validateImpressionTrackingUrl: undefined,
    },
    navLocked: true,
    navWasLocked: false,
    initialEditOffer: '',
    saveDraftOffer: '',
    lastSaved: null,
    pixelGroups: {
        upsell: [],
        offer: [],
        loading: false
    },
    trafficGroups: {
        loading: false,
        data: null,
        error: null
    },
    trafficTypes : [],
    selectedBudgetAmountType : BUDGET_AMOUNT_TYPES[0],
    selectedBudgetGroupAmountType : BUDGET_AMOUNT_TYPES[0],
    trackingSystems : [],
    cpcMainConversionPanelVisibility : false ,
    navState: {
        'offer-info': "active",
        'tracking-system' : 'disabled',
        'land-and-track': 'disabled',
        'outcome-funnel': 'disabled',
        'tracking-codes': 'disabled',
        'testing': 'disabled',
        'creatives-and-email': 'disabled',
        'budget': 'disabled',
        'targeting': 'disabled',
        'scheduling': 'disabled',
    },
    requiredToSubmit: [],
    optionalToSubmit: [],
    lastCreativeId: 0
}

export const fetchAllNiches = createAsyncThunk<any, any,  { state : RootState}>(
    'createOffer/fetchAllNiches',
        async ( _, {getState, rejectWithValue}) =>{
            const state = getState()
            const token = state.auth.token
            try{
                const {data} = await CreateOfferService.fetchNiches(token)
                return data
            } catch (err)   {
                return rejectWithValue("Failed to get the all niches")
            }
        }
)

export const fetchPixelGroups = createAsyncThunk<any, any, {state: RootState}>(
    'createOffer/fetchPixelGroups',
    async (_, {getState, rejectWithValue}) => {
        const state = getState()
        const token = state.auth.token
        try {
            const [upsell, offer] = await Promise.all([
                CreateOfferService.fetchPixelGroups(token, 'upsell'),
                CreateOfferService.fetchPixelGroups(token, 'offer')
            ])
            return {
                upsell: upsell.data,
                offer: offer.data
            }
        } catch (e) {
           return rejectWithValue('Unable to fetch pixel groups')
        }

    }
)

export const fetchTrafficGroups = createAsyncThunk<any, any, {state: RootState, rejectValue: string}>(
    'createOffer/fetchTrafficGroups',
    async (_, {getState, rejectWithValue}) => {
        const state = getState()
        const token = state.auth.token
        try {

            const {data} = await CreateOfferService.fetchTrafficGroups(token)

            return data

        } catch (e) {
            return rejectWithValue('Unable to fetch traffic groups')
        }

    }
)

export const fetchGeoData = createAsyncThunk<any, any, {state: RootState, rejectValue: string}>(
    'createOffer/fetchGeoData',
    async (_, {getState, rejectWithValue}) => {
        const state = getState()
        const token = state.auth.token
        try {

            const [cities, continents, regions, countries] = await Promise.all([
                CreateOfferService.fetchCities(token),
                CreateOfferService.fetchContinents(token),
                CreateOfferService.fetchRegions(token),
                CreateOfferService.fetchCountryState(token)
            ])

            return {
                cities: cities.data,
                countriesState: countries.data,
                continents: continents.data,
                regions: regions.data,
            }

        } catch (e) {
            return rejectWithValue('Unable to fetch geo data')
        }

    }
)

export const fetchIsps = createAsyncThunk<IFetchIspListResponse[], number, {state: RootState, rejectValue: string}>(
    'createOffer/fetchIsps',
    async (id, {getState, rejectWithValue}) => {
        const state = getState()
        const token = state.auth.token
        try {

            const {data} = await CreateOfferService.fetchIspList(token, id)

            return data

        } catch (e) {
            return rejectWithValue('Unable to fetch isp list')
        }

    }
)

export const fetchAccountBalance = createAsyncThunk<IFetchAccountBalanceResponse, number, {state: RootState, rejectValue: string}>(
    'createOffer/fetchAccountBalance',
    async (id, {getState, rejectWithValue}) => {
        const state = getState()
        const token = state.auth.token
        try {

            const {data} = await CreateOfferService.fetchAccountBalance(token, id)

            return data

        } catch (e) {
            return rejectWithValue('Unable to fetch isp list')
        }

    }
)

export const fetchBudgetGroups = createAsyncThunk<any, any, {state : RootState}>(
    'createOffer/fetchBudgetGroups',
    async (_, {getState, rejectWithValue})=>{
        const state = getState()
        const token =state.auth.token
        try{
            const {data} = await CreateOfferService.fetchBudgetGroups(token)
            return data
        }
        catch(e){
            return rejectWithValue('Unable to fetch budget groups')
        }
    }
)

export const fetchEmptyOffer = createAsyncThunk<any, {detailed_pricing_model: DETAILED_PRICING_MODEL | null, pricing_model: string | null}, {state : RootState}>(
    'createOffer/fetchEmptyOffer',
    async ({detailed_pricing_model, pricing_model}, {getState, rejectWithValue})=>{
        const state = getState()
        const token = state.auth.token
        try{
            const {data} = await CreateOfferService.fetchEmptyOffer(token, detailed_pricing_model, pricing_model)
            return data
        }
        catch(err){
            return rejectWithValue('Unable to fetch empty offer!')
        }
    }
)

export const fetchOffer = createAsyncThunk<any, string | number, {state : RootState}>(
    'createOffer/fetchOffer',
    async (id, {getState, rejectWithValue})=>{
        const state = getState()
        const token = state.auth.token
        try{
            const {data} = await CreateOfferService.fetchOffer(token, id)
            return data
        }
        catch(err){
            return rejectWithValue(`Unable to fetch offer with id ${id}!`)
        }
    }
)

export const fetchTrackingSystems = createAsyncThunk<any, string, {state : RootState}>(
    'createOffer/fetchTrackingSystems',
    async (model,{getState, rejectWithValue})=>{
        const state = getState()
        const token = state.auth.token
        try{
            const {data} = await CreateOfferService.fetchTrackingSystem(token, model)
            return data
        }
        catch (e) {
            return rejectWithValue('Unable to fetch tracking systems!')
        }
    }
)

export const createOffer = createAsyncThunk<any, any, {state: RootState}>(
    'createOffer/create',
    async (params, {getState, rejectWithValue}) => {
        const state = getState()
        const token = state.auth.token

        try{
            const {data} = await CreateOfferService.createOffer(token, params)
            return data
        }
        catch(err: any){
            if(err.response.data?.message){
                return rejectWithValue(err.response.data?.message)
            }
            return rejectWithValue('Unable to fetch empty offer!')
        }
    }
)



export const fetchCustomDomains = createAsyncThunk<any, any, {state : RootState}>(
    'createOffer/fetchCustomDomains',
    async (_, {getState, rejectWithValue})=>{
        const state = getState()
        const token =state.auth.token
        const user = state.auth.user
        try{
            const {data} = await CreateOfferService.fetchCustomDomains(token, {
                user_id: user.id,
                status: 'Verified'
            })

            return data
        }
        catch (e) {
            return rejectWithValue('Unable to fetch custom domains')
        }
    }
)


const createOfferSlice = createSlice(
    {
        name: "createOfferSlice",
        initialState,
        reducers: {
            setNavState(state: ICreateOffer, action: PayloadAction<Partial<NavState>>){
                state.navState = {...state.navState, ...action.payload}
            },
            setRequiredToSubmit(state: ICreateOffer, action: PayloadAction<typeof navigationList[number][]>){
                state.requiredToSubmit = action.payload
            },
            setOptionalToSubmit(state: ICreateOffer, action: PayloadAction<typeof navigationList[number][]>){
                state.optionalToSubmit = action.payload
            },
            setOffer(state: ICreateOffer, action: PayloadAction<Partial<IOffer>>) {
                state.offer = {...state.offer, ...action.payload}
                targetOrigin && window.parent.postMessage(
                    {
                        id: 'update_current_campaign',
                        currentCampaign: JSON.stringify({...state.offer, ...action.payload})
                    },
                    targetOrigin
                )
            },
            setNextPage(state: ICreateOffer, action: PayloadAction<string>) {
                state.nextPage = action.payload
            },
            setPrevPage(state: ICreateOffer, action: PayloadAction<string>) {
                state.prevPage = action.payload
            },
            setInitialEditOffer(state: ICreateOffer, action: PayloadAction<string>) {
                state.initialEditOffer = JSON.stringify(action.payload)
            },            
            setSaveDraftOffer(state: ICreateOffer, action: PayloadAction<string>) {
                state.saveDraftOffer = action.payload
            },
            setShowExit(state: ICreateOffer, action: PayloadAction<boolean>) {
                state.showExit = action.payload
            },
            setAppsSelected(state: ICreateOffer, action: PayloadAction<boolean>) {
                state.appsSelected = action.payload
            },
            setShowBack(state: ICreateOffer, action: PayloadAction<boolean>) {
                state.showBack = action.payload
            },
            setAdvancedTargetingExpanded(state: ICreateOffer, action: PayloadAction<boolean>){
                state.advancedTargetingExpanded = action.payload
            },
            setSelectedBudgetAmountType(state: ICreateOffer, action: PayloadAction<SelectOption>){
                state.selectedBudgetAmountType = action.payload
            },
            setSelectedBudgetGroupAmountType(state: ICreateOffer, action: PayloadAction<SelectOption>){
                state.selectedBudgetGroupAmountType = action.payload
            },
            setFieldValidation(state: ICreateOffer, action: PayloadAction<string>) {
                state.fieldValidation = action.payload
            },
            setValidation(state: ICreateOffer, action: PayloadAction<{[key: string]: string | undefined}>) {
                state.validation = {...state.validation, ...action.payload}
            },
            setEventValidation(state: ICreateOffer, action: PayloadAction<IEventValidate>) {
                state.validation.validateEvent =  action.payload
            },
            setEventPostbackUrlValidation(state: ICreateOffer, action: PayloadAction<IEventValidate>) {
                state.validation.validateEventPostbackUrl = action.payload
            },
            setNiches(state: ICreateOffer, action: PayloadAction<INiche[]>) {
                state.niches = action.payload
            },
            setCustomDomains(state: ICreateOffer, action: PayloadAction<ICustomDomain[]>) {
                state.customDomains = action.payload
            },
            setBudgetGroups(state: ICreateOffer, action: PayloadAction<IBudgetGroups[]>) {
                state.budgetGroups = action.payload
            },
            addCreative(state: ICreateOffer, action: PayloadAction<ICreative>) {
                state.offer.creatives.push(action.payload)
            },
            hideServerError(state: ICreateOffer) {
                state.createOfferState.error = null
            },
            setServerError(state: ICreateOffer, action: PayloadAction<string>) {
                state.createOfferState.error = action.payload
            },
            setCpcMainConversionPanelVisibility(state: ICreateOffer, action: PayloadAction<boolean>) {
                state.cpcMainConversionPanelVisibility = action.payload
            },
            setLastSaved(state: ICreateOffer, action: PayloadAction<Date | null>) {
                state.lastSaved = action.payload
            },
            //TODO: add typechecking
            setUpsellPixelGroup(
                state: ICreateOffer,
                action: PayloadAction<{
                    id: number,
                    pixelGroup: any
                }>
            ) {
        
            },
            setLastCreativeId(state: ICreateOffer, action: PayloadAction<number>){
                state.lastCreativeId = action.payload
            },
        },
            extraReducers: (builder : any) => {
                builder
                    .addCase(fetchAllNiches.fulfilled, (state: ICreateOffer, action: PayloadAction<INiche[]>) => {
                        state.niches = action.payload
                    })
                    .addCase(fetchAllNiches.rejected , (state: ICreateOffer, action: PayloadAction<INiche[]>) => {
                        state.niches = []
                    })
                    .addCase(fetchAllNiches.pending , (state: ICreateOffer, action: PayloadAction<INiche[]>) => {
                    })

                    //PIXEL GROUPS
                    .addCase(
                        fetchPixelGroups.pending,
                        (state: ICreateOffer, action: PayloadAction<{
                            upsell: IFetchPixelGroupsResponse[],
                            offer: IFetchPixelGroupsResponse[],
                        }>) => {
                            state.pixelGroups = {
                                ...state.pixelGroups,
                                loading: true,
                            }
                        }
                    )
                    .addCase(
                        fetchPixelGroups.fulfilled,
                        (state: ICreateOffer, action: PayloadAction<{
                            upsell: IFetchPixelGroupsResponse[],
                            offer: IFetchPixelGroupsResponse[],
                        }>) => {
                            state.pixelGroups = {
                                loading: false,
                                offer: action.payload.offer,
                                upsell: action.payload.upsell
                            }
                        },
                    )
                    //TRAFFIC GROUPS
                    .addCase(
                        fetchTrafficGroups.fulfilled,
                        (state: ICreateOffer, action: PayloadAction<IFetchTrafficGroupsResponse[]>) => {
                            state.trafficGroups = {
                                loading: false,
                                data: action.payload,
                                error: null
                            }

                            state.offer.traffic_types = [...action.payload[0].types.map(type => {
                                return {
                                    ...type,
                                    selected: true
                                }
                            }), ...action.payload[1].types.map(type => {
                                return {
                                    ...type,
                                    selected: false
                                }
                            })]
                            state.trafficTypes = [...action.payload[0].types.map(type => {
                                return {
                                    ...type,
                                    selected: true
                                }
                            }), ...action.payload[1].types.map(type => {
                                return {
                                    ...type,
                                    selected: false
                                }
                            })]
                        }
                    )
                    .addCase(
                        fetchTrafficGroups.pending,
                        (state: ICreateOffer, action: PayloadAction<IFetchTrafficGroupsResponse[]>) => {
                            state.trafficGroups = {
                                loading: true,
                                data: null,
                                error: null
                            }
                        }
                    )
                    .addCase(
                        fetchTrafficGroups.rejected,
                        (state: ICreateOffer, action: PayloadAction<string>) => {
                            state.trafficGroups = {
                                loading: false,
                                data: null,
                                error: action.payload
                            }
                        }
                    )

                    //GEO DATA
                    .addCase(
                        fetchGeoData.fulfilled,
                        (state: ICreateOffer, action: PayloadAction<IFetchGeoDataResponse>) => {
                            state.geoData = {
                                loading: false,
                                data: action.payload,
                                error: null
                            }
                        }
                    )
                    .addCase(
                        fetchGeoData.pending,
                        (state: ICreateOffer, action: PayloadAction<IFetchGeoDataResponse>) => {
                            state.geoData = {
                                loading: true,
                                data: {
                                    continents: null,
                                    cities: null,
                                    countriesState: null,
                                    regions: null
                                },
                                error: null
                            }
                        }
                    )
                    .addCase(
                        fetchGeoData.rejected,
                        (state: ICreateOffer, action: PayloadAction<string>) => {
                            state.geoData = {
                                loading: false,
                                data: {
                                    continents: null,
                                    cities: null,
                                    countriesState: null,
                                    regions: null
                                },
                                error: action.payload
                            }
                        }
                    )

                    //isps
                    .addCase(
                        fetchIsps.fulfilled,
                        (state: ICreateOffer, action: PayloadAction<IFetchIspListResponse[]>) => {
                            state.isps = {
                                loading: false,
                                data: {
                                    isps: action.payload
                                },
                                error: null
                            }
                        }
                    )
                    .addCase(
                        fetchIsps.pending,
                        (state: ICreateOffer, action: PayloadAction<IFetchIspListResponse>) => {
                            state.isps = {
                                loading: true,
                                data: {
                                    isps: []
                                },
                                error: null
                            }
                        }
                    )
                    .addCase(
                        fetchIsps.rejected,
                        (state: ICreateOffer, action: PayloadAction<string>) => {
                            state.isps = {
                                loading: false,
                                data: {
                                   isps: []
                                },
                                error: action.payload
                            }
                        }
                    )
                    
                    // BUDGET GROUPS
                    .addCase(fetchBudgetGroups.fulfilled, (state: ICreateOffer, action: PayloadAction<IBudgetGroups[]>)=>{
                        state.budgetGroups = action.payload
                    })
                    .addCase(fetchBudgetGroups.rejected, (state: ICreateOffer, action: PayloadAction<IBudgetGroups[]>)=>{
                        state.budgetGroups = []
                    })
                
                    // EMPTY OFFER
                    .addCase(fetchEmptyOffer.fulfilled, (state : ICreateOffer, action : PayloadAction<any>) =>{
                        state.emptyOffer = action.payload
                    })
                    .addCase(fetchEmptyOffer.rejected, (state : ICreateOffer, action : PayloadAction<any>) =>{
                        state.emptyOffer = {}
                        state.offer.id = 0
                    } )
                    // tracking systems
                    .addCase(fetchTrackingSystems.fulfilled, (state : ICreateOffer, action : PayloadAction<any>) =>{
                        state.trackingSystems = action.payload
                    })
                    .addCase(fetchTrackingSystems.rejected, (state : ICreateOffer, action : PayloadAction<any>) =>{
                        state.trackingSystems = []
                    })

                    //CREATE OFFER
                    .addCase(createOffer.pending, (state: ICreateOffer, action: PayloadAction<any>) => {
                        state.createOfferState = {
                            loading: true,
                            error: null,
                        }
                    })
                    .addCase(createOffer.rejected, (state: ICreateOffer, action: PayloadAction<any>) => {
                        state.createOfferState = {
                            loading: false,
                            error: action.payload,
                        }
                    })
                    .addCase(createOffer.fulfilled, (state: ICreateOffer, action: PayloadAction<any>) => {
                        state.createOfferState = {
                            loading: false,
                            error: null,
                        }
                    })
                    .addCase(fetchCustomDomains.fulfilled, (state: ICreateOffer, action: PayloadAction<IPagination<ICustomDomain>>) => {
                        state.customDomains = action.payload.data
                    })
                    .addCase(fetchAccountBalance.fulfilled, (state: ICreateOffer, action: PayloadAction<IFetchAccountBalanceResponse>) => {
                        state.accountBalance = action.payload
                    })
        }
    })

export const {
    setOffer,
    setNavState,
    setCustomDomains,
    setNextPage,
    setAppsSelected,
    setPrevPage,
    setLastCreativeId,
    setShowExit,
    addCreative,
    setShowBack,
    setInitialEditOffer,
    setSaveDraftOffer,
    setRequiredToSubmit,
    setLastSaved,
    setOptionalToSubmit,
    setSelectedBudgetAmountType,
    setSelectedBudgetGroupAmountType,
    setFieldValidation,
    setValidation,
    setEventValidation,
    setEventPostbackUrlValidation,
    setCpcMainConversionPanelVisibility,
    setUpsellPixelGroup,
    hideServerError,
    setServerError
} = createOfferSlice.actions

export default createOfferSlice.reducer;

/*
*TODO:
*  - add types for creatives-and-email and additional creatives-and-email
*  - add types for traffic types
* */