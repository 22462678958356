import {createGlobalStyle} from "styled-components";
import {
    monthlyRemainingOpportunityHeaderTooltip
} from "../modules/admin/pages/reports/budgets-report/table/tooltips/tooltips";

export default createGlobalStyle`

    //Reset start

    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }

    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }

    body {
        line-height: 1;
    }

    ol, ul {
        list-style: none;
    }

    blockquote, q {
        quotes: none;
    }

    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    //Reset end

    html {
        box-sizing: border-box;
        height: 100%;
    }
    *, *:before, *:after {
        box-sizing: inherit;
    }

    body{
        font-size: 12px;
        height: 100%;
        line-height: 1.42857143;
        font-family: 'Avenir LT W01_55 Roman1475520', sans-serif;
        background-color: #F7F9FA;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overflow-x: hidden !important;
        &::-webkit-scrollbar {
            height: 8px;
            width: 8px;
        }
        &::-webkit-scrollbar-track {
            background:  ${({theme}) => theme.colors.coolGray[2]};
            border-radius: 10px;
            height: 8px;
            width: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background: ${({theme}) => theme.colors.coolGray[3]};
            border-radius: 8px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #6E6E6E;
        }
        &::-webkit-scrollbar-thumb:active {
            background: #585858;
        }
        &::-webkit-scrollbar-corner {
            display: none;
            background-color: transparent;
        }
    }

    sup {
        font-size: 75%;
        vertical-align: 0.45em;
        line-height: 1;
    }

    #root {
        height: 100%;
    }

    .modal-opened {
        &:before {
            content: "";
            position: fixed;
            width: 100%;
            height: 100%;
            background: rgba(49, 56, 59, 0.75);
            z-index: 9999;
            top: 0;
        }
    }

    .truncate-text{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    //helpers
    .hidden, .hide {
        display: none!important;
    }

    a { text-decoration: none; }

    .link-orange {
        color: ${({theme}) => theme.colors.performOrange[6]};
        :hover {
            color: ${({theme}) => theme.colors.performOrange[7]};
        }
    }

    .link-blue {
        color: ${({theme}) => theme.colors.performBlue[5]};
        cursor: pointer;
        text-decoration: none;
        &:hover{
            color: ${({theme}) => theme.colors.performOrange[6]};
        }
    }
    
    .ag-root-wrapper-body{
        color: ${({theme}) => theme.colors.deepBlue[7]};

        .expand-table-header{
            padding: 0 !important;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            cursor: pointer;
        }
    }

    /**
    AG Grid horizontal scrollbar design
     */
    .ag-body-horizontal-scroll-viewport{
        min-height: 8px !important;
        max-height: 8px !important;
        height: 8px !important;
        overflow-x: auto;
        &::-webkit-scrollbar {
            height: 8px;
            width: 8px;
        }
        &::-webkit-scrollbar-track {
            background:  ${({theme}) => theme.colors.pureWhite};
            border-radius: 10px;
            height: 8px;
            width: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background: ${({theme}) => theme.colors.coolGray[3]};
            border-radius: 8px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: ${({theme}) => theme.colors.deepBlue[2]};
        }
        &::-webkit-scrollbar-thumb:active {
            background: ${({theme}) => theme.colors.deepBlue[3]};
        }
    }
    .ag-body-horizontal-scroll, .ag-horizontal-left-spacer, .ag-horizontal-right-spacer  {
        overflow: auto;
        min-height: 8px !important;
        max-height: 8px !important;
        height: 8px !important;
    }

    .header-right-text-align{
        width: 100%;
        text-align: right;
        padding-right: 16px;
    }

    .header-center-text-align{
        width: 100%;
        text-align: center;
        padding-right: 16px;
    }

    strong {
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    }

    .text-capitalize {
        text-transform: capitalize;
    }
    .align-right{
        text-align: right;
    }

    b {
        font-family: ${({theme}) => theme.fonts.AvenirMedium};
    }


    strong, .strong {
        font-family: ${({theme}) => theme.fonts.AvenirHeavy}
    }

    .custom-scrollbar {
        &::-webkit-scrollbar {
            height: 8px;
            width: 8px;
        }

        &::-webkit-scrollbar-track {
            border-radius: 10px;
            height: 8px;
            width: 8px;
            background: ${({theme}) => theme.colors.coolGray[2]};
        }

        &::-webkit-scrollbar-thumb {
            background: ${({theme}) => theme.colors.coolGray[3]};
            border-radius: 8px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: ${({theme}) => theme.colors.deepBlue[2]};
        }

        &::-webkit-scrollbar-thumb:active {
            background: ${({theme}) => theme.colors.deepBlue[3]};
        }
    }

    .sort-icon-down {
        &:after {
            transform: translateY(2px);
            margin-left: 2px;
            display: inline-block;
            content: url("data:image/svg+xml,%3Csvg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.00063 0.000976562C5.13324 0.000976563 5.26042 0.0461232 5.35419 0.126485C5.44795 0.206846 5.50063 0.31584 5.50063 0.429489V10.5364L8.64663 7.83932C8.74052 7.75886 8.86786 7.71365 9.00063 7.71365C9.13341 7.71365 9.26075 7.75886 9.35463 7.83932C9.44852 7.91978 9.50126 8.02892 9.50126 8.14271C9.50126 8.2565 9.44852 8.36563 9.35463 8.44609L5.35463 11.8742C5.30819 11.9141 5.25301 11.9458 5.19226 11.9674C5.13152 11.989 5.0664 12.0001 5.00063 12.0001C4.93486 12.0001 4.86974 11.989 4.809 11.9674C4.74825 11.9458 4.69308 11.9141 4.64663 11.8742L0.646631 8.44609C0.552745 8.36563 0.5 8.2565 0.5 8.14271C0.5 8.02892 0.552745 7.91978 0.646631 7.83932C0.740518 7.75886 0.867856 7.71365 1.00063 7.71365C1.13341 7.71365 1.26075 7.75886 1.35463 7.83932L4.50063 10.5364V0.429489C4.50063 0.31584 4.55331 0.206846 4.64708 0.126485C4.74085 0.0461232 4.86802 0.000976563 5.00063 0.000976562Z' fill='%23FF5E38'/%3E%3C/svg%3E%0A");
        }
    }
    
    .hide-scrollbar {
      ::-webkit-scrollbar {
          display: none;
      }
    }
    
    .sort-icon-up {
        &:after {
            display: inline-block;
            margin-left: 2px;
            transform: rotate(180deg) translateY(2px);
            content: url("data:image/svg+xml,%3Csvg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.00063 0.000976562C5.13324 0.000976563 5.26042 0.0461232 5.35419 0.126485C5.44795 0.206846 5.50063 0.31584 5.50063 0.429489V10.5364L8.64663 7.83932C8.74052 7.75886 8.86786 7.71365 9.00063 7.71365C9.13341 7.71365 9.26075 7.75886 9.35463 7.83932C9.44852 7.91978 9.50126 8.02892 9.50126 8.14271C9.50126 8.2565 9.44852 8.36563 9.35463 8.44609L5.35463 11.8742C5.30819 11.9141 5.25301 11.9458 5.19226 11.9674C5.13152 11.989 5.0664 12.0001 5.00063 12.0001C4.93486 12.0001 4.86974 11.989 4.809 11.9674C4.74825 11.9458 4.69308 11.9141 4.64663 11.8742L0.646631 8.44609C0.552745 8.36563 0.5 8.2565 0.5 8.14271C0.5 8.02892 0.552745 7.91978 0.646631 7.83932C0.740518 7.75886 0.867856 7.71365 1.00063 7.71365C1.13341 7.71365 1.26075 7.75886 1.35463 7.83932L4.50063 10.5364V0.429489C4.50063 0.31584 4.55331 0.206846 4.64708 0.126485C4.74085 0.0461232 4.86802 0.000976563 5.00063 0.000976562Z' fill='%23FF5E38'/%3E%3C/svg%3E%0A");
        }
    }
    
    
    .styled-select {
        &__placeholder {
            color: ${({theme}) => theme.colors.coolGray[4]};
        }
        &__control {
            min-height: 40px;
            border-radius: 8px;
            transition: all .2s ease-in-out;
            border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
            color: ${({theme}) => theme.colors.deepBlue[4]};
            box-shadow: none;

            &:hover {
                cursor: pointer;
                border-color: ${({theme}) => theme.colors.coolGray[4]};
            }

            &--menu-is-open {
                .styled-select__single-value {
                    color: ${({theme}) => theme.colors.coolGray[3]};
                }
            }

            &--menu-is-open, &--is-focused {
                border-color: #25c0d5 !important;
                &:hover {
                    border-color: #25c0d5!important;
                }
            }

            &--is-focused {
                border-color: #25c0d5!important;
                background-color: white;

                :hover {
                    background-color: white!important;
                }
            }
            &--is-disabled {
                background-color: white!important;
                .styled-select__indicator {
                    color: ${({theme}) => theme.colors.coolGray[4]};
                }
            }
        }

        &__indicator-separator {
            display: none;
        }

        &__indicator {
            padding: 8px 12px;
            color: ${({theme}) => theme.colors.deepBlue[4]};
            &:before {
                font-size: 13px;
                line-height: 1;
                font-family: 'leap';
                content: '\\e91f';
            }

            svg {
                display: none;
            }
        }

        &__menu {
            border-radius: 8px !important;
            border: 1px solid ${({theme}) => theme.colors.coolGray[3]} !important;
            box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
            background-color: white;
            padding: 16px;
            margin-top: 4px !important;
            z-index: 2;
            
            &--list {
                padding: 0;
            }

        }

        &__option {
            padding: 8px;
            border-radius: 8px;
            font-family: ${({theme}) => theme.fonts.AvenirRoman};
            font-size: 12px;
            background-color: white!important;
            color: #5a686f;
            cursor: pointer;
            :hover {
                background-color: #e7f9fb!important;
            }

            &--is-selected:not(.multiselect) {
                color: white;
                background-color: #2d4548!important;

                &:hover {
                    background-color: #2d4548!important;
                }
            }
            &--is-disabled {
                opacity: .4;
                pointer-events: none;
            }
        }
        &__single-value {
            color: ${({theme}) => theme.colors.deepBlue[4]};
            &--is-disabled {
                color: #D2D7D9;
            }
        }
        &__input-container {
            input {
                color: #8799a2!important;
            }
        }
        &__group {
            &-heading {
                color: ${({theme}) => theme.colors.deepBlue[7]};
                font-family: ${({theme}) => theme.fonts.AvenirHeavy};
                font-size: 12px;
                text-transform: capitalize;
                padding-left: 0;
                
            }
        }
        &--is-disabled {
        
        }
        
    } 
    
    .button-reset{
        background: none;
        padding: 0;
        margin: 0;
        border: none;
        cursor: pointer;
    }

    .ag-custom-no-data-overlay {
        .ag-layout-auto-height {
            overflow: visible;
            pointer-events: none;
        }
        .ag-root-wrapper {
            min-height: 240px;
        }
        
        .ag-body-horizontal-scroll-viewport{
            overflow: hidden;
        }

        .ag-custom-no-data-message  {
            height: 200px;
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${({theme}) => theme.colors.deepBlue[5]};
            font-family: ${({theme}) => theme.fonts.AvenirHeavy};
            i {
                margin-left: 5px;
            }
        }
    }

    .cell-text-positive{
        color: ${({theme}) => theme.colors.positive[7]};
    }

    .cell-text-negative{
        color: ${({theme}) => theme.colors.negative[7]};
    }

    .text-truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .text-wrap {
        word-wrap: break-word;
    }

    .checkbox-form-group-horizontal {
        display: inline-flex;
        flex-wrap: wrap;
        gap: 16px;
        margin-bottom: 32px;
        .form-group-label {
            font-family: ${({theme}) => theme.fonts.AvenirHeavy};
            line-height: 16px;
            color: ${({theme}) => theme.colors.deepBlue[5]};
        }
    }
    
    .checkbox-form-group-vertical {
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;
        gap: 8px;
        .form-group-label {
            font-family: ${({theme}) => theme.fonts.AvenirHeavy};
            line-height: 16px;
            color: ${({theme}) => theme.colors.deepBlue[5]};
            margin-bottom: 5px;
        }
    }
    
    .no-x-padding{
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    
    //Fund Risk value colors
    .fund-risk-value-5 {
        background: ${({theme}) => theme.colors.negative[8]};
    }

    .fund-risk-value-4 {
        background: ${({theme}) => theme.colors.negative[6]};
    }

    .fund-risk-value-3 {
        background: ${({theme}) => theme.colors.juicedOrange[5]};
    }

    .fund-risk-value-2 {
        background: ${({theme}) => theme.colors.warning[5]};
    }

    .fund-risk-value-1 {
        background: ${({theme}) => theme.colors.positive[6]};
    }

    .fund-risk-value-0 {
        background: ${({theme}) => theme.colors.deepBlue[4]};
    }
    
    // Budget value colors
    .budget-value-1 {
        background: ${({theme}) => theme.colors.negative[6]};
    }

    .budget-value-2 {
        background: ${({theme}) => theme.colors.juicedOrange[5]};
    }

    .budget-value-3 {
        background: ${({theme}) => theme.colors.positive[6]};
    }

    .budget-value-4 {
        background: ${({theme}) => theme.colors.performBlue[4]};
    }

    .budget-value-5 {
        background: ${({theme}) => theme.colors.deepBlue[1]};
    }
    
    //Rating Stars
    .rating-stars {
        display: flex;
        align-items: center;
        gap: 2px;
        i {
            color: ${({theme}) => theme.colors.coolGray[3]};
            font-size: 17px;
            &.active {
                color: ${({theme}) => theme.colors.performBlue[5]};
            }
        }
    }

    .no-margin {
        margin: 0 !important;
    }
    
    em {
        font-family: ${({theme}) => theme.fonts.AvenirLightItalic};
    }
    
    .cell-data-pill-wrapper {
        gap : 2px;
        min-width: 54px;
        line-height: 1;
        padding: 2px 8px;
        font-size: 12px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        &-positive{
            background: ${({theme}) => theme.colors.positive[1]};
            color: ${({theme}) => theme.colors.positive[7]};
        }

        &-negative{
            background: ${({theme}) => theme.colors.negative[1]};
            color: ${({theme}) => theme.colors.negative[7]};
        }
        
        &-neutral{
            background: ${({theme}) => theme.colors.deepBlue[1]};
            color: ${({theme}) => theme.colors.deepBlue[7]};
        }

        &-outlined{
            background: white;
            color: ${({theme}) => theme.colors.deepBlue[5]};
            border: 1px solid ${({theme}) => theme.colors.deepBlue[1]};
        }
    }
    .flex-cell{
        display: flex;
        gap: 8px;
    }
`
