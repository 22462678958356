import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    IFetchAllAccountManagersAndMarketersResponse,
    IFetchPartnersResponse,
    TFilterType
} from "../common/types";
import {NONE} from "../../const";
import {INicheFilter} from "../../../../../../common/hooks/queries/filters/useNichesFilterQuery";


export interface IEngineGrowthPartnerViewInitialState {
    filterType: TFilterType,
    include: boolean,
    selectedManagers : IFetchAllAccountManagersAndMarketersResponse[],
    selectedVerticals: INicheFilter[]
    selectedPartners: IFetchPartnersResponse[],
    largestMovements: ILargestMovementsState
    partnerHealth: IPartnerHealthState
    newPartners: INewestPartnersState
    lowestMargins: ILowestMarginsState
    budgetReport: ILowestMarginsState
    fromDashboardToPartnerRegistrations: boolean,
    isEngineGMColumnHidden: boolean,
    engineAMColumnHidden: boolean,
}

export interface INewestPartnersState {
    page: number,
    perPage: number,
    search: string,
    type: "productive_partners" | "non-productive_partners"
}

export interface ILargestMovementsState {
    page: number,
    perPage: number,
    search: string,
    scaling: boolean
}

export interface IPartnerHealthState {
    page: number,
    perPage: number,
    q: string
}
export interface ILowestMarginsState {
    page: number,
    perPage: number,
    q: string,
}

const initialState : IEngineGrowthPartnerViewInitialState = {
    filterType: NONE,
    include: true,
    selectedManagers: [],
    selectedVerticals: [],
    selectedPartners: [],
    largestMovements: {
        page: 1,
        perPage: 5,
        search: '',
        scaling: true
    },
    partnerHealth: {
        page: 1,
        perPage: 10,
        q: '',
    },
    newPartners: {
        page: 1,
        perPage: 5,
        search: '',
        type: 'productive_partners'
    },
    lowestMargins: {
        page: 1,
        perPage: 5,
        q: '',
    },
    budgetReport: {
        page: 1,
        perPage: 10,
        q: '',
    },
    fromDashboardToPartnerRegistrations: false,
    isEngineGMColumnHidden: true,
    engineAMColumnHidden: true,
}

const engineGrowthPartnerViewSlice = createSlice({
    name: 'engineGrowthPartnerViewSlice',
    initialState,
    reducers: {
        setFilterType: (state : IEngineGrowthPartnerViewInitialState, action : PayloadAction<TFilterType>) =>{
            state.selectedManagers = []
            state.selectedVerticals = []
            state.selectedPartners = []
            state.filterType = action.payload
        },
        setIncludeExclude: (state: IEngineGrowthPartnerViewInitialState, action: PayloadAction<boolean>) => {
            state.include = action.payload
        },
        addOrRemoveFilterManager: (state: IEngineGrowthPartnerViewInitialState, action: PayloadAction<IFetchAllAccountManagersAndMarketersResponse>) => {
            if(state.selectedManagers.some(manager => manager.id === action.payload.id)){
                state.selectedManagers = state.selectedManagers.filter(manager => manager.id !== action.payload.id)
            } else {
                state.selectedManagers.push(action.payload)
            }
        },
        clearSelectedManagers: (state: IEngineGrowthPartnerViewInitialState) => {
            state.selectedManagers = []
        },
        addOrRemoveFilterVertical: (state: IEngineGrowthPartnerViewInitialState, action: PayloadAction<INicheFilter>) => {
            if(state.selectedVerticals.some(item => item.id === action.payload.id)){
                state.selectedVerticals = state.selectedVerticals.filter(item => item.id !== action.payload.id)
            } else {
                state.selectedVerticals.push(action.payload)
            }
        },
        clearSelectedVerticals: (state: IEngineGrowthPartnerViewInitialState) => {
            state.selectedVerticals = []
        },
        addOrRemoveFilterPartner: (state: IEngineGrowthPartnerViewInitialState, action: PayloadAction<IFetchPartnersResponse>) => {
            if(state.selectedPartners.some(item => item.platform_partner_id === action.payload.platform_partner_id)){
                state.selectedPartners = state.selectedPartners.filter(item => item.platform_partner_id !== action.payload.platform_partner_id)
            } else {
                state.selectedPartners.push(action.payload)
            }
        },
        clearSelectedPartners: (state: IEngineGrowthPartnerViewInitialState) => {
            state.selectedPartners = []
        },
        setLargestMovementsState: (state: IEngineGrowthPartnerViewInitialState, action: PayloadAction<Partial<ILargestMovementsState>>) => {
            state.largestMovements = {...state.largestMovements, ...action.payload}
        },
        setPartnerHealthState: (state: IEngineGrowthPartnerViewInitialState, action: PayloadAction<Partial<IPartnerHealthState>>) => {
            state.partnerHealth = {...state.partnerHealth, ...action.payload}
        },
        setNewPartnersState: (state: IEngineGrowthPartnerViewInitialState, action: PayloadAction<Partial<INewestPartnersState>>) => {
            state.newPartners = {...state.newPartners, ...action.payload}
        },
        setLowestMarginsState: (state: IEngineGrowthPartnerViewInitialState, action: PayloadAction<Partial<ILowestMarginsState>>) => {
            state.lowestMargins = {...state.lowestMargins, ...action.payload}
        },
        setBudgetReportState : (state: IEngineGrowthPartnerViewInitialState, action: PayloadAction<Partial<ILowestMarginsState>>) => {
            state.budgetReport = {...state.budgetReport, ...action.payload}
        },
        clearFilters: (state: IEngineGrowthPartnerViewInitialState) => {
            state.selectedManagers = []
            state.selectedVerticals = []
            state.selectedPartners = []
            state.filterType = "none"
            state.include = true
        },
        setFromDashboardToPartnerRegistrations: (state: IEngineGrowthPartnerViewInitialState, action: PayloadAction<boolean>) => {
            state.fromDashboardToPartnerRegistrations = action.payload
        },
        setIsEngineGMColumnHidden: (state: IEngineGrowthPartnerViewInitialState, action: PayloadAction<boolean>) => {
            state.isEngineGMColumnHidden = action.payload
        },
        setIsEngineAMColumnHidden: (state: IEngineGrowthPartnerViewInitialState, action: PayloadAction<boolean>) => {
            state.engineAMColumnHidden = action.payload
        }
    }
})

export const {
    setFilterType,
    setIncludeExclude,
    addOrRemoveFilterManager,
    clearSelectedManagers,
    clearFilters,
    addOrRemoveFilterVertical,
    clearSelectedVerticals,
    addOrRemoveFilterPartner,
    clearSelectedPartners,
    setLargestMovementsState,
    setPartnerHealthState,
    setNewPartnersState,
    setFromDashboardToPartnerRegistrations,
    setIsEngineAMColumnHidden,
    setLowestMarginsState,
    setBudgetReportState
} = engineGrowthPartnerViewSlice.actions

export default engineGrowthPartnerViewSlice.reducer