import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {NETWORK, VIEWS} from "./const";

interface IInitialState {
    view: VIEWS
}

const initialState: IInitialState = {
    view: NETWORK
}

const adminDashboardSlice = createSlice({
    name: 'adminDashboardSlice',
    initialState,
    reducers: {
        setView: (state, action: PayloadAction<VIEWS>) => {
            state.view = action.payload
        }
    }
})

export default adminDashboardSlice.reducer
export const {setView} = adminDashboardSlice.actions