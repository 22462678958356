

export const AGENCY =  "agency"

export const NETWORK = "network"

export const ENGINE = "engine"

export const PARTNER = "partner"

export const ENGINE_GROWTH = "engine_growth"

export const AGENCY_GROWTH = "agency_growth"

export const ENGINE_GROWTH_PARTNER = "engine_growth_partner"

// export const GROWTH = "growth"

export type VIEWS = 
    typeof AGENCY | 
    typeof NETWORK | 
    typeof ENGINE_GROWTH | 
    typeof AGENCY_GROWTH | 
    typeof ENGINE_GROWTH_PARTNER


export const NONE =  'none'
export const MARKETER = 'marketer'
export const ENGINE_GROWTH_MANAGER = 'engine_growth_manager'
export const AGENCY_GROWTH_MANAGER = 'agency_growth_manager'
export const VERTICAL = 'vertical'

export const PARTNER_GROWTH_MANAGER = 'partner_growth_manager'

export const MARKETER_TIER = 'marketer_tier'

export const OVERDUE = 'Overdue'
export const DUE_SOON = 'Due Soon'
export const UPCOMING = 'Upcoming'

export type REMINDER_TYPE =  typeof OVERDUE | typeof DUE_SOON | typeof UPCOMING
