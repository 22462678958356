export const ALL = "all"
export const TODAY = "today"
export const YESTERDAY = "yesterday"
export const THIS_WEEK = "this_week"
export const LAST_WEEK = "last_week"
export const THIS_MONTH = "this_month"
export const LAST_MONTH = "last_month"
export const THIS_YEAR = "this_year"
export const LAST_7_DAYS = "last_7_days"
export const WEEK_TO_DATE = "week_to_date"
export const MONTH_TO_YESTERDAY = "month_to_yesterday"
export const LAST_30_DAYS = "last_30_days"
export const QUARTER_TO_DATE = "quarter_to_date"
export const LAST_QUARTER = "last_quarter"
export const YEAR_TO_YESTERDAY = "year_to_yesterday"
export const YEAR_TO_LAST_FULL_MONTH = "year_to_last_full_month"
export const LAST_YEAR = "last_year"
export const PREVIOUS_PERIOD = "previous_period"
export const PREVIOUS_YEAR = "previous_year"
export const PREVIOUS_YEAR_DOW = "previous_year_dow"
export const PREVIOUS_FULL_YEAR = "previous_full_year"
export const CUSTOM = "custom"
export const MONTH_TO_DATE = "month_to_date"
export const YEAR_TO_DATE = "year_to_date"

export const MONTHLY = "monthly" 
export const QUARTERLY= "quarterly" 
export const YEARLY= "yearly" 
export const WEEKLY= "weekly"

export type DatePresetType =
    typeof ALL |
    typeof TODAY |
    typeof YESTERDAY |
    typeof THIS_WEEK |
    typeof LAST_WEEK |
    typeof THIS_MONTH |
    typeof LAST_MONTH|
    typeof THIS_YEAR |
    typeof LAST_7_DAYS |
    typeof WEEK_TO_DATE |
    typeof MONTH_TO_YESTERDAY |
    typeof QUARTER_TO_DATE |
    typeof LAST_QUARTER |
    typeof YEAR_TO_YESTERDAY |
    typeof YEAR_TO_LAST_FULL_MONTH |
    typeof LAST_YEAR |
    typeof PREVIOUS_PERIOD |
    typeof PREVIOUS_YEAR |
    typeof PREVIOUS_YEAR_DOW |
    typeof PREVIOUS_FULL_YEAR |
    typeof CUSTOM |
    typeof MONTH_TO_DATE |
    typeof YEAR_TO_DATE |
    typeof LAST_30_DAYS

export type DatePreset = {
    value: DatePresetType,
    name: string,
    type?: typeof MONTHLY | typeof QUARTERLY | typeof YEARLY | typeof WEEKLY
}

export type DatePresetsMap = {
    [key in DatePresetType]: DatePreset
}