import axios from "axios";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const apiUrl = process.env.REACT_APP_API_URL

export const apiInstance = axios.create({
    baseURL: apiBaseUrl + '/' + apiUrl,
    headers: {
        'Content-Type': 'application/json'
    }
});
