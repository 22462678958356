import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface IInitialState {
    initialDelay: number
}

const initialState : IInitialState ={
    initialDelay: 50
}

const tooltipSlice = createSlice({
    name: 'tooltips',
    initialState,
    reducers: {
        setTooltipDelay :(
            state : IInitialState,
            action: PayloadAction<number>
        ) => {
            state.initialDelay = action.payload
        }
    }
})

export default tooltipSlice.reducer

export const {
    setTooltipDelay
} = tooltipSlice.actions
