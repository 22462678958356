interface Return {
    percentage: number,
    type: 'positive' | 'negative' | 'neutral',
    difference: number
}

export const calculateDifference = (
    firstValue: number | string | undefined,
    secondValue: number | string | undefined,
    decimals?: number
): Return => {

    if(firstValue === undefined || secondValue === undefined) {
        return {
            percentage: 0,
            type: 'neutral',
            difference: 0
        }
    }

    if(firstValue === 0 && secondValue === 0) {
        return {
            percentage: 0,
            type: 'neutral',
            difference: 0
        }
    }

    const firstValueNumber =
        typeof firstValue === 'string' ?
            Number(parseFloat(firstValue).toFixed(decimals ?? 2)) :
            Number(firstValue.toFixed(decimals ?? 2))
    const secondValueNumber =
        typeof secondValue === 'string' ?
            Number(parseFloat(secondValue).toFixed(decimals ?? 2)) :
            Number(secondValue.toFixed(decimals ?? 2))

    let percentage = 0
    let difference = 0
    let type: 'positive' | 'negative' | 'neutral' = 'neutral'

    if(firstValueNumber !== 0 && secondValueNumber !== 0) {
        percentage = Number(
            Math.abs(
                ((firstValueNumber - secondValueNumber) / secondValueNumber) * 100
            ).toFixed(decimals ?? 2)
        )
        difference = firstValueNumber - secondValueNumber
    }else if(firstValueNumber !== 0 && secondValueNumber === 0) {
        percentage = Number((100).toFixed(decimals ?? 2))
        difference = firstValueNumber
    }else if(firstValueNumber === 0 && secondValueNumber !== 0) {
        percentage = Number((-100).toFixed(decimals ?? 2))
        difference = secondValueNumber
    }

    if(firstValueNumber > secondValueNumber) {
        type = 'positive'
    } else if(firstValueNumber < secondValueNumber) {
        type = 'negative'
    }
    return {
        percentage,
        type,
        difference
    }
}