import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { IPreset } from "../../../../common/components/range-date-picker/presets/presets.props";
import { IFilterPresetsResponse } from "./DynamicReport.service";
import {IColumn} from "./customize-report/columnsAndFilters";
import {format, subDays} from "date-fns";

export enum visibility {
    INITIAL_SHOW_OWNED_BY,
    ADMIN,
    PRIVATE,
    SELECTED_USERS,
    PUBLIC,
}

export const BASIC_PRESET: IFilterPresetsResponse = {
    id: 0,
    name: 'Basic CAP View',
    owner_id: 0,
    owner_name: '',
    data : JSON.stringify({
        paginate_by : 50,
        columns: JSON.stringify([
            "impressions",
            "total_clicks",
            "clicks",
            "total_conversions",
            "approved_conversions",
            "total_events",
            "events",
            "cvr",
            "attach_rate",
            "epc",
            "total_revenue",
            "valid_revenue",
            "cost",
            "approved_profit",
            "total_profit",
            "margin",
        ])
    }),
    visibility: visibility.PRIVATE,
    is_default: null,
    user_id: null,
    is_owner_of_preset: 0,
    user_list: [],
    is_basic_view: true,
    presetData : {
        paginate_by : 50,
        columns : [
            "impressions",
            "total_clicks",
            "clicks",
            "total_conversions",
            "approved_conversions",
            "total_events",
            "events",
            "cvr",
            "attach_rate",
            "epc",
            "total_revenue",
            "valid_revenue",
            "cost",
            "approved_profit",
            "total_profit",
            "margin",
        ],
        date_range : "last_7_days",
        from_date : format(subDays(new Date(), 7), "yyyy-MM-dd"),
        until_date: format(subDays(new Date(), 1), "yyyy-MM-dd"),
        sort_by: 'impressions',
        order: 'desc'
    }
}

export interface IInitialState  {
    sort: {
        direction: 'desc' | 'asc',
        by: string
    },
    per_page: number,
    date_from: Date | null | undefined,
    date_to: Date | null | undefined,
    compare_date_from: Date | null | undefined,
    compare_date_to: Date | null | undefined,
    date_range_preset: IPreset,
    compare_date_preset: IPreset,
    filter_presets: IFilterPresetsResponse[],
    default_filter_preset: IFilterPresetsResponse
    selected_columns : IColumn[],
    compare_visibility : boolean,
    compare_button_disabled : boolean,
    use_stats_table?: boolean
}

const initialState: IInitialState = {
    sort: {
        direction: "desc",
        by: 'impressions'
    },
    per_page: 0,
    date_from: subDays(new Date(Date.now()), 7),
    date_to: subDays(new Date(Date.now()), 1),
    compare_date_from: subDays(new Date(Date.now()), 7),
    compare_date_to: subDays(new Date(Date.now()), 1),
    date_range_preset: {
        value: "last_7_days",
        name: 'Last 7 days'
    },
    compare_date_preset: {
        value: "previous_period",
        name: 'Previous Period'
    },
    filter_presets: [],
    default_filter_preset: BASIC_PRESET,
    selected_columns: [
        {
            id :"impressions",
            name: 'Impressions',
            column_name: 'impressions',
            columnFromFlex: false,
        },
        {
            id :"total_clicks",
            name: 'Clicks Total',
            column_name: 'total_clicks',
            columnFromFlex: false,
        },
        {
            id :"clicks",
            name: 'Clicks',
            column_name: 'clicks',
            columnFromFlex: false,
        },
        {
            id :"total_conversions",
            name: 'Conversions Total',
            column_name: 'total_conversions',
            columnFromFlex: false,
        },
        {
            id :"approved_conversions",
            name: 'Conversions Approved',
            column_name: 'approved_conversions',
            columnFromFlex: false,
        },
        {
            id :"total_events",
            name: 'Events Total',
            column_name: 'total_events',
            columnFromFlex: false,
        },
        {
            id :"events",
            name: 'Events Approved',
            column_name: 'events',
            columnFromFlex: false,
        },
        {
            id :"cvr",
            name: 'CVR',
            column_name: 'cvr',
            columnFromFlex: false,
        },
        {
            id :"attach_rate",
            name: 'Event Rate',
            column_name: 'attach_rate',
            columnFromFlex: false,
        },
        {
            id :"epc",
            name: 'EPC',
            column_name: 'epc',
            columnFromFlex: false,
        },
        {
            id :"total_revenue",
            name: 'Spend Total',
            column_name: 'total_revenue',
            columnFromFlex: false,
        },
        {
            id :"valid_revenue",
            name: 'Spend Approved',
            column_name: 'valid_revenue',
            columnFromFlex: false,
        },
        {
            id :"cost",
            name: 'Commission Approved',
            column_name: 'cost',
            columnFromFlex: false,
        },
        {
            id :"approved_profit",
            name: 'Net Revenue Approved',
            column_name: 'approved_profit',
            columnFromFlex: false,
        },
        {
            id :"total_profit",
            name: 'Net Revenue Total',
            column_name: 'total_profit',
            columnFromFlex: false,
        },
        {
            id :"margin",
            name: 'Margin Approved',
            column_name: 'margin',
            columnFromFlex: false,
        },
    ],
    compare_visibility: false,
    compare_button_disabled: true,
    use_stats_table: false
}

const dynamicReportSlice = createSlice({
    name: 'dynamicReport',
    initialState,
    reducers: {
        setSort: (state: IInitialState, action:PayloadAction<{
            direction: 'desc' | 'asc',
            by: string
        }>) => {
            state.sort = action.payload
        },
        setPerPage: (state: IInitialState, action:PayloadAction<number>) => {
            state.per_page = action.payload;
        },
        setDateFrom: (state: IInitialState, action: PayloadAction<Date | null | undefined>) => {
            state.date_from = action.payload
        },
        setDateTo: (state: IInitialState, action: PayloadAction<Date | null | undefined>) => {
            state.date_to = action.payload
        },
        setCompareDateFrom: (state: IInitialState, action: PayloadAction<Date | null | undefined>) => {
            state.compare_date_from = action.payload
        },
        setCompareDateTo: (state: IInitialState, action: PayloadAction<Date | null | undefined>) => {
            state.compare_date_to = action.payload
        },
        setDateRangePreset: (state: IInitialState, action:PayloadAction<IPreset>) => {
            state.date_range_preset = action.payload
        },
        setCompareDateRangePreset: (state: IInitialState, action:PayloadAction<IPreset>) => {
            state.compare_date_preset = action.payload
        },
        setFilterPresets: (state: IInitialState, action:PayloadAction<IFilterPresetsResponse[]>) => {
            state.filter_presets = action.payload
        },
        setActiveFilterPreset: (state: IInitialState, action:PayloadAction<IFilterPresetsResponse>) => {
            state.default_filter_preset = action.payload
        },
        setSelectedColumns: (state, action: PayloadAction<IColumn>) => {
            if(state.selected_columns.some(column => column.id === action.payload.id )) {
                state.selected_columns = [...state.selected_columns.filter(column => column.id !== action.payload.id)]
            }else {
                state.selected_columns = [...state.selected_columns, action.payload]
            }
        },
        setColumns: (state, action: PayloadAction<IColumn[]>) => {state.selected_columns = action.payload},
        removeSelectedColumns: (state) => {
            state.selected_columns = [] 
        },
        setCompareVisibility: (state: IInitialState, action: PayloadAction<boolean>) => {
            state.compare_visibility = action.payload
        },
        setCompareButtonDisabled: (state: IInitialState, action: PayloadAction<boolean>) => {
            state.compare_button_disabled = action.payload
        },
        setUseStatsTable: (state: IInitialState, action: PayloadAction<boolean>) => {
            state.use_stats_table = action.payload
        },
    }
})
export const {
    setSort,
    setPerPage,
    setDateFrom,
    setDateTo,
    setDateRangePreset,
    setCompareDateFrom,
    setCompareDateRangePreset,
    setCompareDateTo,
    setFilterPresets,
    setActiveFilterPreset,
    setSelectedColumns,
    setColumns,
    removeSelectedColumns,
    setCompareVisibility,
    setCompareButtonDisabled,
    setUseStatsTable
} = dynamicReportSlice.actions

export default dynamicReportSlice.reducer
