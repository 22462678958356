import {combineReducers, createStore, AnyAction, Dispatch, ThunkDispatch, applyMiddleware} from '@reduxjs/toolkit'
import {persistReducer} from "redux-persist";
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import {composeWithDevTools} from "@redux-devtools/extension";
import storage from "redux-persist/lib/storage";
import thunkMiddleware from 'redux-thunk'
import authReducer from "./auth.slice";
import advertisersEditReducer, {IAdvertisersEditState} from '../modules/admin/pages/advertisers-edit/AdvertisersEdit.slice'
import adminDashboardReducer from '../modules/admin/pages/dashboard/Dashboard.slice'
import agencyViewReducer, {IAgencyViewInitialState} from '../modules/admin/pages/dashboard/agency-view/AgencyView.slice'
import networkViewReducer, {INetworkViewInitialState} from '../modules/admin/pages/dashboard/network-view/NetworkView.slice'
import engineGroupViewReducer, {IEngineGrowthViewInitialState} from '../modules/admin/pages/dashboard/growth-views/engine-growth-view/EngineGrowthView.slice'
import agencyGrowthViewReducer, {IAgencyGrowthViewInitialState} from '../modules/admin/pages/dashboard/growth-views/agency-growth-view/AgencyGrowthView.slice'
import advertisersReducer, {InitState as IAdvertisersInitState} from '../modules/admin/pages/advertisers/Advertisers.slice';
import partnerReducer from '../modules/partner/pages/referrals/Referrals.slice';
import leapPartnersReducer, {IInitialState as ILeapPartnersInitialState} from '../modules/admin/pages/partners/leap-partners/LeapPartners.slice'
import filtersReducer, {IFiltersInitialState} from './filters.slice'
import dynamicFiltersReducer from './dynamic-filters.slice'
import offersReducer, {IOffersInitialState} from '../modules/admin/pages/offers/offers/Offers.slice'
import billingTransactionsReducer, {IBillingTransactionsInitialState} from '../modules//admin/pages/advertisers-edit/billing/billing-transactions/BillingTransactions.slice'
import billingFeesReducer, {IInitialState as IBillingFeesInitialState} from '../modules//admin/pages/advertisers-edit/billing/billing-fees/BillingFees.slice';
import dynamicReportReducer from '../modules/admin/pages/dynamic-report/DynamicReport.slice';
import marketerFundingMonitoringReducer, {IInitialState as IMarketerFundingMonitoringInitialState}
    from "../modules/admin/pages/billing/marketer-funding-monitoring/MarketerFundingMonitoring.slice";
import partnerTransactionsReducer, {IInitialState as IPartnerTransactionsInitialState} from '../modules//admin/pages/billing/partner-transactions/PartnerTransactions.slice';
import partnerPaymentsSlice, {IInitialState as IPartnerPaymentState} from "../modules/admin/pages/billing/partner-payments/PartnerPayments.slice";
import marketerBalanceReducer, {IInitialState as IMarketerBalanceInitialState} from '../modules/admin/pages/billing/marketer-balance/MarketerBalance.slice';
import marketersTransactionsReducer, {IInitialState as IMarketersTransactionsInitialState} from '../modules/admin/pages/billing/marketers-transactions/MarketersTransactions.slice'
import tableReducer, {ITableSliceInitialState} from './table.slice'
import userPreferencesReducer from './userPreferences.slice'
import cpaOffersReducer from '../modules/advertiser/pages/offers/cpa/Cpa.slice'
import adminUsersReducer, {InitStateAdminUsers} from "../modules/admin/pages/users/Users.slice";
import createOfferReducer from "../modules/advertiser/pages/create-offer/create-offer.slice"

import mergedPartnersReducer, {InitState as IMergedPartnersInitialState} from "../modules/admin/pages/partners/partner_merging/merged/Merged.slice";
import nonMergedPartnersReducer, {InitState as INonMergedPartnersInitialState} from "../modules/admin/pages/partners/partner_merging/non_merged/NonMerged.slice";
import tooltipReducer from './tooltip.slice'
import agencyMarketerReducer from "../modules/agency-marketer/AgencyMarketer.slice";
import agencyDynamicReportReducer from "../modules/agency-marketer/dynamic-report/DynamicReport.slice";
import partnersRegistrationReducer from "../modules/admin/pages/partner-registration/PartnersRegistration.slice";
import eventsSliceReducer from "../modules/admin/pages/tools/events-adjustment/events/Events.slice";
import AutomatedAdjustmentsReducer from '../modules/admin/pages/tools/events-adjustment/automated-adjustments/AutomatedAdjustments.slice';

import marketerDynamicReportReducer from "../modules/advertiser/pages/dynamic-report/DynamicReport.slice";
import LogRocket from "logrocket";
import engineGrowthPartnerViewReducer, {IEngineGrowthPartnerViewInitialState} from "../modules/admin/pages/dashboard/growth-views/engine-growth-partner-view/EngineGrowthPartnerView.slice";

export const config = {
    key: 'root',
    storage: storage,
    blacklist: [
        'advertisersEdit',
        'auth',
        'offers',
        'filters',
        'marketerFundingMonitoring',
        'networkView',
        'leapPartners',
        'table',
        'userPreferences',
        'advertiserOffersCpa',
        'userPreferences',
        'adminUsers',
        'dynamicReport',
        'dynamicFilters',
        'dynamic',
        'createOffer',
        'tooltips',
        'engineGrowthView',
        'agencyGrowthView',
        'agencyMarketer',
        'agencyDynamicReport',
        'partnersRegistration',
        'marketerDynamicReport',
    ],
    stateReconciler: autoMergeLevel2
};

const advertisersEditPersistConfig = {
    key: 'advertisersEdit',
    storage: storage,
    blacklist: ['error', 'saveUserError', 'alertModal', 'alertModalOpened', 'loading', 'editingUser'],
    stateReconciler: autoMergeLevel2,
}

const agencyViewPersistConfig = {
    key: 'agencyView',
    storage: storage,
    blacklist: ['date_from', 'date_to'],
    stateReconciler: autoMergeLevel2,
}

const networkViewPersistConfig = {
    key: 'networkView',
    storage: storage,
    blacklist: ['pagination', 'topPerformersTableData', 'topPerformersTableSum', 'topPerformersLoading'],
    stateReconciler: autoMergeLevel2,
}
const engineGrowthViewPersistConfig = {
    key: 'engineGrowthViewSlice',
    storage: storage,
    stateReconciler: autoMergeLevel2,
}

const engineGrowthPartnerViewPersistConfig = {
    key: 'engineGrowthPartnerViewSlice',
    storage: storage,
    stateReconciler: autoMergeLevel2,
}

const agencyGrowthViewPersistConfig = {
    key: 'agencyGrowthViewSlice',
    storage: storage,
    blacklist: ['editMergingPartnerDetailsModalState'],
    stateReconciler: autoMergeLevel2,
}

const advertisersPersistConfig = {
    key: 'advertisers',
    storage: storage,
    stateReconciler: autoMergeLevel2,
}

const marketerFundingMonitoringPersistConfig = {
    key: 'marketerFundingMonitoring',
    storage: storage,
    blacklist: ['pagination'],
    stateReconciler: autoMergeLevel2,
}

const offersPersistConfig = {
    key: 'offers',
    storage: storage,
    blacklist: ['selected_offers'],
    stateReconciler: autoMergeLevel2,
}

const billingTransactionsConfig = {
    key: 'billingTransactions',
    storage: storage,
    blacklist: ['pagination'],
    stateReconciler: autoMergeLevel2,
}

const billingFeesConfig = {
    key: 'billingFees',
    storage: storage,
    stateReconciler: autoMergeLevel2,
}

const marketerBalanceConfig = {
    key: 'marketerBalance',
    storage: storage,
    stateReconciler: autoMergeLevel2,
}

const partnerTransactionsConfig = {
    key: 'partnerTransactions',
    storage: storage,
    stateReconciler: autoMergeLevel2,
}

const filtersPersistConfig = {
    key: 'filter',
    storage: storage,
    stateReconciler: autoMergeLevel2,
}

const tablePersistConfig = {
    key: 'table',
    storage: storage,
    stateReconciler: autoMergeLevel2,
}

const marketersTransactionsPersistConfig = {
    key: 'marketersTransactions',
    storage: storage,
    stateReconciler: autoMergeLevel2,
}

const leapPartnerPersistConfig = {
    key: 'leapPartners',
    storage: storage,
    blacklist: [],
    stateReconciler: autoMergeLevel2,
}

const partnerPaymentPersistConfig = {
    key: 'partnerPayment',
    storage: storage,
    stateReconciler: autoMergeLevel2,
}

const adminUsersPersistConfig = {
    key: 'adminUsers',
    storage: storage,
    blacklist: ["selectedUser", "users"],
    stateReconciler: autoMergeLevel2,
}

const mergedPartnerPersistConfig = {
    key: 'mergedPartners',
    storage: storage,
    stateReconciler: autoMergeLevel2,
}

const nonMergedPartnerPersistConfig = {
    key: 'nonMergedPartners',
    storage: storage,
    stateReconciler: autoMergeLevel2,
}

const dynamicReportPersistConfig = {
    key: 'dynamicReport',
    storage: storage,
    stateReconciler: autoMergeLevel2,
}

const rootReducer = combineReducers({
    auth: authReducer,
    advertisersEdit: persistReducer<IAdvertisersEditState>(advertisersEditPersistConfig ,advertisersEditReducer),
    adminDashboard: adminDashboardReducer,
    agencyView: persistReducer<IAgencyViewInitialState>(agencyViewPersistConfig, agencyViewReducer),
    networkView: persistReducer<INetworkViewInitialState>(networkViewPersistConfig, networkViewReducer),
    engineGrowthView: persistReducer<IEngineGrowthViewInitialState>(engineGrowthViewPersistConfig, engineGroupViewReducer),
    engineGrowthPartnerView: persistReducer<IEngineGrowthPartnerViewInitialState>(engineGrowthPartnerViewPersistConfig, engineGrowthPartnerViewReducer),
    agencyGrowthView: persistReducer<IAgencyGrowthViewInitialState>(agencyGrowthViewPersistConfig, agencyGrowthViewReducer),
    advertisers: persistReducer<IAdvertisersInitState>(advertisersPersistConfig, advertisersReducer),
    partner: partnerReducer,
    filters: persistReducer<IFiltersInitialState<any>>(filtersPersistConfig,filtersReducer),
    // dynamicFilters: persistReducer(dynamicFiltersPersistConfig,dynamicFiltersReducer),
    dynamicFilters: dynamicFiltersReducer,
    marketerFundingMonitoring: persistReducer<IMarketerFundingMonitoringInitialState>(marketerFundingMonitoringPersistConfig, marketerFundingMonitoringReducer),
    offers: persistReducer<IOffersInitialState>(offersPersistConfig, offersReducer),
    billingTransactions: persistReducer<IBillingTransactionsInitialState>(billingTransactionsConfig, billingTransactionsReducer),
    billingFees: persistReducer<IBillingFeesInitialState>(billingFeesConfig, billingFeesReducer),
    partnerTransactions: persistReducer<IPartnerTransactionsInitialState>(partnerTransactionsConfig, partnerTransactionsReducer),
    partnerPayment: persistReducer<IPartnerPaymentState>(partnerPaymentPersistConfig, partnerPaymentsSlice),
    leapPartners: persistReducer<ILeapPartnersInitialState>(leapPartnerPersistConfig ,leapPartnersReducer),
    marketerBalance: persistReducer<IMarketerBalanceInitialState>(marketerBalanceConfig, marketerBalanceReducer),
    marketersTransactions: persistReducer<IMarketersTransactionsInitialState>(marketersTransactionsPersistConfig, marketersTransactionsReducer),
    table: tableReducer,
    userPreferences: userPreferencesReducer,
    advertiserOffersCpa: cpaOffersReducer,
    adminUsers : persistReducer<InitStateAdminUsers>(adminUsersPersistConfig, adminUsersReducer),
    mergedPartners: persistReducer<IMergedPartnersInitialState>(mergedPartnerPersistConfig, mergedPartnersReducer),
    nonMergedPartners: persistReducer<INonMergedPartnersInitialState>(nonMergedPartnerPersistConfig, nonMergedPartnersReducer),
    dynamicReport: dynamicReportReducer,
    createOffer : createOfferReducer,
    tooltips: tooltipReducer,
    agencyMarketer : agencyMarketerReducer,
    agencyDynamicReport: agencyDynamicReportReducer,
    partnersRegistration: partnersRegistrationReducer,
    events: eventsSliceReducer,
    automatedAdjustments: AutomatedAdjustmentsReducer,
    marketerDynamicReport: marketerDynamicReportReducer
})



const persisted = persistReducer<ReturnType<typeof rootReducer>>(config, rootReducer)

// const store = createStore(persisted, applyMiddleware(thunkMiddleware, LogRocket.reduxMiddleware()))
const composedEnhancer = composeWithDevTools({trace: true, traceLimit: 25});

const store = createStore(persisted, composedEnhancer(applyMiddleware(thunkMiddleware)))

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = Dispatch<AnyAction> &
    ThunkDispatch<RootState, null, AnyAction>;

export default store
