import {PageID} from "../../../../store/filters.slice";
import {FilterID} from "filters";
import {IMetricOptions, IPreset} from "../../../../common/components/customize-report/interfaces";
import {
    DatePresetType,
    LAST_7_DAYS,
    LAST_MONTH,
    LAST_QUARTER,
    LAST_WEEK, LAST_YEAR,
    MONTH_TO_YESTERDAY,
    PREVIOUS_FULL_YEAR,
    PREVIOUS_PERIOD,
    PREVIOUS_YEAR,
    PREVIOUS_YEAR_DOW,
    QUARTER_TO_DATE,
    THIS_MONTH,
    THIS_YEAR,
    TODAY,
    WEEK_TO_DATE, YEAR_TO_DATE,
    YEAR_TO_LAST_FULL_MONTH,
    YEAR_TO_YESTERDAY,
    YESTERDAY
} from "../../../../common/models/datePresets";

export const PAGE_ID: PageID = "marketer/dynamic-report"
export const QUERY_NAME = "marketers-dynamic-report"
export const QUERY_NAME_SUMS = "marketers-dynamic-report-sums"
export const QUERY_FILTER_PRESETS = "marketers-dynamic-report-presets"

export enum ColumnsAndFilters {
    AOV = "aov",
    EVENT_RATE = 'attach_rate',
    BROWSER = "group_by_flex_browser",
    BROWSER_VERSION = "group_by_flex_browser_version",
    CITY = "group_by_flex_city",
    CLICKS = "total_clicks",
    CONVERSION_SUBID1 = "group_by_link_flex_conv_subid1",
    CONVERSIONS_TOTAL = "total_conversions",
    COUNTRY = "group_by_flex_country_code",
    CREATIVE_ID = "group_by_flex_creative_id",
    CREATIVE_NAME = "group_by_link_flex_creative_name",
    CREATIVE_TYPE = "group_by_creative_type",
    CVR = "cvr",
    DATE = "group_by_date_day_timestamp",
    DATE_HOUR = "group_by_date_hourly_timestamp",
    CAMPAIGN_STATUS = "detailed_offer_status",
    DEVICE_NAME = "group_by_flex_device_name",
    DEVICE_TYPE = "group_by_flex_device_type",
    DOMAIN = "group_by_flex_domain",
    ECPA = "ecpa",
    EVENT_ID = "group_by_upsell_id",
    EVENT_NAME = "group_by_upsell_name",
    EVENTS = "total_events",
    IMPRESSIONS = "impressions",
    ISP = "group_by_flex_isp",
    LANGUAGE = "group_by_flex_language",
    LINK_ID = "group_by_link_id",
    MONTH = "group_by_date_month_timestamp",
    CAMPAIGN_ID = "group_by_campaign_id",
    CAMPAIGN_NAME = "group_by_campaign_name",
    CAMPAIGN_TYPE = "group_by_offer_type",
    OS = "group_by_flex_os",
    OS_VERSION = "group_by_flex_os_version",
    PARTNER_ID = "group_by_partner_id",
    REFERRER_DOMAIN = "group_by_link_flex_referrer_domain",
    ROAS = "roas",
    SALE_AMOUNT = "revenue_amount",
    SOURCE_ID = "group_by_link_flex_source_id",
    SPEND_TOTAL = "total_revenue",
    STATE = "group_by_flex_state_code",
    SUBID = "group_by_link_flex_subid1",
    SUBID1 = "group_by_link_flex_subid1",
    SUBID2 = "group_by_link_flex_subid2",
    SUBID3 = "group_by_link_flex_subid3",
    SUBID4 = "group_by_link_flex_subid4",
    SUBID5 = "group_by_link_flex_subid5",
    SUBID6 = "group_by_link_flex_subid6",
    TRAFFIC_TYPE = "group_by_traffic_type",
    VERTICAL = "group_by_niche",
    WEEK = "group_by_week_timestamp",
    ZIP = "group_by_flex_zip",
}

/**
 * this list represents names of the columns and data returned from the API
 * table will be sorted by these names
 */
export enum ColumnIDS  {
    AOV = "aov", //done
    EVENT_RATE = 'attach_rate',
    BROWSER = "browser", //done
    BROWSER_VERSION = "browser_version",
    CITY = "city", //done
    CLICKS = "clicks", //done
    CONVERSION_SUBID1 = "conv_subid1", //done
    CONVERSIONS_TOTAL = "total_conversions", //done
    COUNTRY = "country_code", // done
    CREATIVE_ID = "creative_id", //done
    CREATIVE_NAME = "creative_name", //done
    CREATIVE_TYPE = "creative_type", //done
    CVR = "cvr", //done
    DATE = "date_day_timestamp", //done
    DATE_HOUR = "hourly_timestamp", //done
    CAMPAIGN_STATUS= "detailed_offer_status", //done
    DEVICE_NAME = "device_name", //done
    DEVICE_TYPE = "device_type", //done
    DOMAIN = "domain", //done
    ECPA = "ecpa", //done
    EVENT_ID = "event_id", //done
    EVENT_NAME = "upsell_name", //done
    EVENTS = "total_events", //done
    IMPRESSIONS = "impressions", //done
    ISP = "isp", //done
    LANGUAGE = "language", //done
    LINK_ID = "link_id", //done
    MONTH = "date_month_timestamp", //done
    CAMPAIGN_ID = "offer_id", //done
    CAMPAIGN_NAME = "campaign_name", //done
    CAMPAIGN_TYPE = "offer_type", //done
    OS = "os", //done
    OS_VERSION = "os_version", //done
    PARTNER_ID = 'affiliate_id', //done
    REFERRER_DOMAIN = "referrer_domain", //done
    ROAS = "roas", //done
    SALE_AMOUNT = "revenue_amount", //done
    SOURCE_ID = "source_id", //done
    SPEND_TOTAL = "total_revenue", //done
    STATE = "state", //done
    SUBID = "subid1",
    SUBID2 = "subid2", //done
    SUBID3 = "subid3", //done
    SUBID4 = "subid4", //done
    SUBID5 = "subid5", //done
    SUBID6 = "subid6", //done
    TRAFFIC_TYPE = "traffic_type_name", //done
    VERTICAL = "niche_name", //done
    WEEK = "date_week_timestamp", //done
    ZIP = "zip",
}

export const ColumnsAndFiltersMap: Map<ColumnsAndFilters, IMetricOptions> = new Map<ColumnsAndFilters, IMetricOptions>([
        [ColumnsAndFilters.AOV, {
            id: ColumnIDS.AOV,
            columnId: 'aov',
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "AOV",
            disable: false,
            tooltip: "The average order value per sale. The calculation used to determine this: Sale Amount / " +
                "Conversions",
            flex: true
        }],
        [ColumnsAndFilters.EVENT_RATE, {
            id: ColumnsAndFilters.EVENT_RATE,
            columnId: ColumnIDS.EVENT_RATE,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "Event Rate",
            disable: false,
            tooltip: "The percent of customers who complete an event after the conversion. The calculation used to determine this: Events / Conversions. ",
            flex: false
        }],
        [ColumnsAndFilters.BROWSER, {
            id: ColumnsAndFilters.BROWSER,
            columnId: ColumnIDS.BROWSER,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "Browser",
            disable: false,
            tooltip: "The browser type used by the customer when completing a click, conversion or event. ",
            flex: true
        }],
        [ColumnsAndFilters.BROWSER_VERSION, {
            id: ColumnsAndFilters.BROWSER_VERSION,
            columnId: ColumnIDS.BROWSER_VERSION,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "Browser Version",
            disable: false,
            tooltip: "The browser version used by the customer when completing a click, conversion or event.",
            flex: true
        }],
        [ColumnsAndFilters.CITY, {
            id: ColumnsAndFilters.CITY,
            columnId: ColumnIDS.CITY,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "City",
            disable: false,
            tooltip: "Where the customer was located when completing a click, conversion or event.",
            flex: true
        }],
        [ColumnsAndFilters.CLICKS, {
            id: ColumnsAndFilters.CLICKS,
            columnId: ColumnIDS.CLICKS,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "Clicks",
            disable: false,
            tooltip: "The number of times a customer clicked on an ad and was directed to an intended campaign.",
            flex: false
        }],
        [ColumnsAndFilters.CONVERSION_SUBID1, {
            id: ColumnsAndFilters.CONVERSION_SUBID1,
            columnId: ColumnIDS.CONVERSION_SUBID1,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "Conversion SubID1",
            disable: false,
            tooltip: "A value passed from the marketer's tracking platform on the conversion or event(not on the click).",
            flex: true
        }],
        [ColumnsAndFilters.CONVERSIONS_TOTAL, {
            id: ColumnsAndFilters.CONVERSIONS_TOTAL,
            columnId: ColumnIDS.CONVERSIONS_TOTAL,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "Conversions",
            disable: false,
            tooltip: "The number of valid conversions.",
            flex: false
        }],
        [ColumnsAndFilters.COUNTRY, {
            id: ColumnsAndFilters.COUNTRY,
            columnId: ColumnIDS.COUNTRY,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "Country",
            disable: false,
            tooltip: "Where the customer was located when completing a click, conversion or event.",
            flex: false
        }],
        [ColumnsAndFilters.CREATIVE_ID, {
            id: ColumnsAndFilters.CREATIVE_ID,
            columnId: ColumnIDS.CREATIVE_ID,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "Creative ID",
            disable: false,
            tooltip: "A value passed from the partner's tracking platform on the click (or impression). These values can be passed to the destination url (or impression url) if desired. ",
            flex: true
        }],
        [ColumnsAndFilters.CREATIVE_NAME, {
            id: ColumnsAndFilters.CREATIVE_NAME,
            columnId: ColumnIDS.CREATIVE_NAME,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "Creative Name",
            disable: false,
            tooltip: "A value passed from the partner's tracking platform on the click (or impression). These values can be passed to the destination url (or impression url) if desired. ",
            flex: true
        }],
        [ColumnsAndFilters.CREATIVE_TYPE, {
            id: ColumnsAndFilters.CREATIVE_TYPE,
            columnId: ColumnIDS.CREATIVE_TYPE,
            filterId: 'creative-type',
            isFilter: true,
            isColumn: true,
            name: "Creative Type",
            disable: false,
            tooltip: "The type assigned to a creative: text, banner, etc.",
            flex: true
        }],
        [ColumnsAndFilters.CVR, {
            id: ColumnsAndFilters.CVR,
            columnId: ColumnIDS.CVR,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "CVR",
            disable: false,
            tooltip: "The conversion rate is the percent of clicks that convert to a conversion. " +
                "The calculation used to determine this: Conversions Approved / Clicks",
            flex: false
        }],
        [ColumnsAndFilters.DATE, {
            id: ColumnsAndFilters.DATE,
            columnId: ColumnIDS.DATE,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "Date",
            disable: false,
            tooltip: "The date of the click, conversion or event. This is in EST timezone. ",
            flex: false
        }],
        [ColumnsAndFilters.DATE_HOUR, {
            id: ColumnsAndFilters.DATE_HOUR,
            columnId: ColumnIDS.DATE_HOUR,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "Date/Hour",
            disable: false,
            tooltip: "The date and hour of the click, conversion or event. This is in EST timezone. ",
            flex: false
        }],
        [ColumnsAndFilters.CAMPAIGN_STATUS, {
            id: ColumnsAndFilters.CAMPAIGN_STATUS,
            columnId: ColumnIDS.CAMPAIGN_STATUS,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "Campaign Status",
            disable: false,
            tooltip: "Current status of the campaign. ",
            flex: false
        }],
        [ColumnsAndFilters.DEVICE_NAME, {
            id: ColumnsAndFilters.DEVICE_NAME,
            columnId: ColumnIDS.DEVICE_NAME,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'Device Name',
            disable: false,
            tooltip: 'The specific device model used by the customer when completing a click, conversion or event. ',
            flex: true
        }],
        [ColumnsAndFilters.DEVICE_TYPE, {
            id: ColumnsAndFilters.DEVICE_TYPE,
            columnId: ColumnIDS.DEVICE_TYPE,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'Device Type',
            disable: false,
            tooltip: 'The type of device used by the customer when completing a click, conversion or event. ',
            flex: false
        }],
        [ColumnsAndFilters.DOMAIN, {
            id: ColumnsAndFilters.DOMAIN,
            columnId: ColumnIDS.DOMAIN,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'Domain',
            disable: false,
            tooltip: 'Perform[cb]\'s URL domain associated to each click, conversion and event.',
            flex: true
        }],
        [ColumnsAndFilters.ECPA, {
            id: ColumnsAndFilters.ECPA,
            columnId: ColumnIDS.ECPA,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'ECPA',
            disable: false,
            tooltip: "\"Effective CPA is the average amount spent for each action. The calculation used to determine this: \n" +
                "Spend / (Conversions + Events)\"",
            flex: false
        }],
        [ColumnsAndFilters.EVENT_ID, {
            id: ColumnsAndFilters.EVENT_ID,
            columnId: ColumnIDS.EVENT_ID,
            filterId: 'events',
            isFilter: true,
            isColumn: true,
            name: 'Event ID',
            disable: false,
            tooltip: "The unique identification number assigned to an event.",
            flex: false
        }],
        [ColumnsAndFilters.EVENT_NAME, {
            id: ColumnsAndFilters.EVENT_NAME,
            columnId: ColumnIDS.EVENT_NAME,
            filterId: 'events',
            isFilter: true,
            isColumn: true,
            name: 'Event Name',
            disable: false,
            tooltip: 'The name added to the event when creating the campaign. ',
            flex: false
        }],
        [ColumnsAndFilters.EVENTS, {
            id: ColumnsAndFilters.EVENTS,
            columnId: ColumnIDS.EVENTS,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'Events',
            disable: false,
            tooltip: 'The number of valid events.',
            flex: false
        }],
        [ColumnsAndFilters.IMPRESSIONS, {
            id: ColumnsAndFilters.IMPRESSIONS,
            columnId: ColumnIDS.IMPRESSIONS,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'Impressions',
            disable: false,
            tooltip: 'The number of times the campaign was displayed to a customer. ',
            flex: false
        }],
        [ColumnsAndFilters.ISP, {
            id: ColumnsAndFilters.ISP,
            columnId: ColumnIDS.ISP,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'ISP',
            disable: false,
            tooltip: 'The internet service provider used by the customer when completing a click, conversion or event.',
            flex: true
        }],
        [ColumnsAndFilters.LANGUAGE, {
            id: ColumnsAndFilters.LANGUAGE,
            columnId: ColumnIDS.LANGUAGE,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'Language',
            disable: false,
            tooltip: 'The browser language (English, etc) used by the customer when completing a click, conversion or event.',
            flex: true
        }],
        [ColumnsAndFilters.LINK_ID, {
            id: ColumnsAndFilters.LINK_ID,
            columnId: ColumnIDS.LINK_ID,
            filterId: 'links',
            isFilter: false,
            isColumn: true,
            name: 'Link ID',
            disable: false,
            tooltip: 'Perform[cb]\'s unique identification number assigned to a link.',
            flex: false
        }],
        [ColumnsAndFilters.MONTH, {
            id: ColumnsAndFilters.MONTH,
            columnId: ColumnIDS.MONTH,
            filterId: '',
            name: 'Month',
            isColumn: true,
            isFilter: false,
            disable: false,
            tooltip: 'The month the click, conversion or event occured. This is in EST timezone. ',
            flex: false
        }],
        [ColumnsAndFilters.CAMPAIGN_ID, {
            id: ColumnsAndFilters.CAMPAIGN_ID,
            columnId: ColumnIDS.CAMPAIGN_ID,
            filterId: 'campaigns',
            isFilter: true,
            isColumn: true,
            name: "Campaign ID",
            disable: false,
            tooltip: "Perform[cb]'s unique identification number assigned to an campaign.",
            flex: false
        }],
        [ColumnsAndFilters.CAMPAIGN_NAME, {
            id: ColumnsAndFilters.CAMPAIGN_NAME,
            columnId: ColumnIDS.CAMPAIGN_NAME,
            filterId: 'campaigns',
            isFilter: true,
            isColumn: true,
            name: "Campaign Name",
            disable: false,
            tooltip: "The campaign name will show through to partners.",
            flex: false
        }],
        [ColumnsAndFilters.CAMPAIGN_TYPE, {
            id: ColumnsAndFilters.CAMPAIGN_TYPE,
            columnId: ColumnIDS.CAMPAIGN_TYPE,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "Campaign Type",
            disable: false,
            tooltip: "Defines what type of outcome is being paid on for the campaign. ",
            flex: false
        }],
        [ColumnsAndFilters.OS, {
            id: ColumnsAndFilters.OS,
            columnId: ColumnIDS.OS,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'OS',
            disable: false,
            tooltip: 'The device operating system used by the customer when completing a click, conversion or event.',
            flex: false
        }],
        [ColumnsAndFilters.OS_VERSION, {
            id: ColumnsAndFilters.OS_VERSION,
            columnId: ColumnIDS.OS_VERSION,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'OS Version',
            disable: false,
            tooltip: 'The device operating system version used by the customer when completing a click, conversion or event.',
            flex: true
        }],
        [ColumnsAndFilters.PARTNER_ID, {
            id: ColumnsAndFilters.PARTNER_ID,
            columnId: ColumnIDS.PARTNER_ID,
            filterId: 'affiliate',
            isFilter: false,
            isColumn: true,
            name: 'Partner ID',
            disable: false,
            tooltip: 'Perform[cb]\'s unique identification number assigned to a partner.',
            flex: false
        }],
        [ColumnsAndFilters.REFERRER_DOMAIN, {
            id: ColumnsAndFilters.REFERRER_DOMAIN,
            columnId: ColumnIDS.REFERRER_DOMAIN,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'Referrer Domain',
            disable: false,
            tooltip: 'The site the customer was referred to Perform[cb] from. This is controlled by the partner; if empty, the partner has chosen to hide this information.',
            flex: true
        }],
        [ColumnsAndFilters.ROAS, {
            id: ColumnsAndFilters.ROAS,
            columnId: ColumnIDS.ROAS,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'ROAS',
            disable: false,
            tooltip: "\"The return on ad spend. \n" +
                "The calculation used to determine this: Sale Amount / Spend\"",
            flex: true
        }],
        [ColumnsAndFilters.SALE_AMOUNT, {
            id: ColumnsAndFilters.SALE_AMOUNT,
            columnId: ColumnIDS.SALE_AMOUNT,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'Sale Amount',
            disable: false,
            tooltip: "The amount made in sales. This is passed to Perform[cb] via the &saleamount parameter.",
            flex: true
        }],
        [ColumnsAndFilters.SOURCE_ID, {
            id: ColumnsAndFilters.SOURCE_ID,
            columnId: ColumnIDS.SOURCE_ID,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'Source ID',
            disable: false,
            tooltip: 'A value passed from the partner\'s tracking platform on the click (or impression). These values can be passed to the destination url (or impression url) if desired. ',
            flex: true
        }],
        [ColumnsAndFilters.SPEND_TOTAL, {
            id: ColumnsAndFilters.SPEND_TOTAL,
            columnId: ColumnIDS.SPEND_TOTAL,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'Spend',
            disable: false,
            tooltip: "The amount spent on advertising for traffic from Perform[cb].",
            flex: false
        }],
        [ColumnsAndFilters.STATE, {
            id: ColumnsAndFilters.STATE,
            columnId: ColumnIDS.STATE,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'State',
            disable: false,
            tooltip: 'Where the customer was located when completing a click, conversion or event. ',
            flex: false
        }],
        [ColumnsAndFilters.SUBID, {
            id: ColumnsAndFilters.SUBID,
            columnId: ColumnIDS.SUBID,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'SubID',
            disable: false,
            tooltip: 'A value passed from the marketer\'s tracking platform on the click (or impression). These values can be passed to the destination url (or impression url) if desired. ',
            flex: true
        }],
        [ColumnsAndFilters.SUBID2, {
            id: ColumnsAndFilters.SUBID2,
            columnId: ColumnIDS.SUBID2,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'SubID2',
            disable: false,
            tooltip: 'A value passed from the marketer\'s tracking platform on the click (or impression). These values can be passed to the destination url (or impression url) if desired. ',
            flex: true
        }],
        [ColumnsAndFilters.SUBID3, {
            id: ColumnsAndFilters.SUBID3,
            columnId: ColumnIDS.SUBID3,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'SubID3',
            disable: false,
            tooltip: 'A value passed from the marketer\'s tracking platform on the click (or impression). These values can be passed to the destination url (or impression url) if desired. ',
            flex: true
        }],
        [ColumnsAndFilters.SUBID4, {
            id: ColumnsAndFilters.SUBID4,
            columnId: ColumnIDS.SUBID4,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'SubID4',
            disable: false,
            tooltip: 'A value passed from the marketer\'s tracking platform on the click (or impression). These values can be passed to the destination url (or impression url) if desired. ',
            flex: true
        }],
        [ColumnsAndFilters.SUBID5, {
            id: ColumnsAndFilters.SUBID5,
            columnId: ColumnIDS.SUBID5,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'SubID5',
            disable: false,
            tooltip: 'A value passed from the marketer\'s tracking platform on the click (or impression). These values can be passed to the destination url (or impression url) if desired. ',
            flex: true
        }],
        [ColumnsAndFilters.SUBID6, {
            id: ColumnsAndFilters.SUBID6,
            columnId: ColumnIDS.SUBID6,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'SubID6',
            disable: false,
            tooltip: 'A value passed from the marketer\'s tracking platform on the click (or impression). These values can be passed to the destination url (or impression url) if desired. ',
            flex: true
        }],
        [ColumnsAndFilters.TRAFFIC_TYPE, {
            id: ColumnsAndFilters.TRAFFIC_TYPE,
            columnId: ColumnIDS.TRAFFIC_TYPE,
            filterId: 'traffic-type',
            isFilter: true,
            isColumn: true,
            name: 'Traffic Type',
            disable: false,
            tooltip: 'The type of traffic associated to the partner\'s link.',
            flex: false
        }],
        [ColumnsAndFilters.VERTICAL, {
            id: ColumnsAndFilters.VERTICAL,
            columnId: ColumnIDS.VERTICAL,
            filterId: 'niche',
            isFilter: true,
            isColumn: true,
            name: 'Vertical',
            disable: false,
            tooltip: 'The industry channel that the marketer provides goods and services within. ',
            flex: false
        }],
        [ColumnsAndFilters.WEEK, {
            id: ColumnsAndFilters.WEEK,
            columnId: ColumnIDS.WEEK,
            filterId: '',
            name: 'Week',
            isColumn: true,
            isFilter: false,
            disable: false,
            tooltip: 'The week of the click, conversion or event. This is in EST timezone.',
            flex: false
        }],
        [ColumnsAndFilters.ZIP, {
            id: ColumnsAndFilters.ZIP,
            columnId: ColumnIDS.ZIP,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'Zip',
            disable: false,
            tooltip: 'The zip code for where the customer was located when completing a click, conversion or event. ',
            flex: false
        }],
    ]
)

export const ALL_FILTERS: {
    id: FilterID,
    value: string
}[] = [
    {
        id: 'campaigns',
        value: 'Campaign'
    },
    {
        id: 'events',
        value: 'Event'
    },
    {
        id: 'creative-type',
        value: 'Creative Type'
    },
    // {
    //     id: 'campaign-statuses',
    //     value: 'Campaign Status'
    // },
    {
        id: 'offer-type',
        value: 'Campaign Type'
    },
    {
        id: 'traffic-type',
        value: 'Traffic Type'
    },
    {
        id: 'niche',
        value: 'Vertical'
    },
]

export const  BASIC_PRESET : IPreset = {
    type: "agency",
    preset_name: "Analyze By Month",
    isDefault: true,
    selected_column_ids: [
        ColumnsAndFilters.MONTH,
        ColumnsAndFilters.SPEND_TOTAL,
        ColumnsAndFilters.CONVERSIONS_TOTAL,
        ColumnsAndFilters.CLICKS,
        ColumnsAndFilters.CVR,
        ColumnsAndFilters.ECPA
    ],
    filters: [
        {
            id: "campaigns",
            exclude: false,
            selected: [

            ]
        },
        {
            id: "events",
            exclude: true,
            selected: [

            ]
        },

    ],
    paginate_by : 50,
    page: 1,
    sort_by : ColumnsAndFilters.CONVERSIONS_TOTAL,
    order: "desc",
    isCompareActive: false,
    sums: false,
    dateRange: {
        preset: YEAR_TO_DATE,
        from: new Date(),
        to: new Date()
    },
    compareDateRange: null
}


export const FilterMapping = new Map<FilterID, string>(
    [
        ['creative-name', 'creative_name'],
        ['events', 'upsells'],
        ['customer-type', 'customer_type'],
        ['creative-type', 'creative_type'],
        ['links', 'links'],
        ['partner-company-name', 'partner_company_name' ],
        ['traffic-type', 'traffic_types']
    ]
)

export const REGULAR_PRESETS: DatePresetType[] = [
    TODAY,
    YESTERDAY,
    WEEK_TO_DATE,
    LAST_7_DAYS,
    LAST_WEEK,
    THIS_MONTH,
    MONTH_TO_YESTERDAY,
    LAST_MONTH,
    QUARTER_TO_DATE,
    LAST_QUARTER,
    THIS_YEAR,
    YEAR_TO_YESTERDAY,
    YEAR_TO_LAST_FULL_MONTH,
    LAST_YEAR,
]

export const COMPARE_PRESETS: DatePresetType[] = [
    PREVIOUS_PERIOD ,
    PREVIOUS_YEAR ,
    PREVIOUS_YEAR_DOW ,
    PREVIOUS_FULL_YEAR
]
