
export const getRisk = (risk : number) => {
    switch (risk) {
        case 0:
            return 'No Risk'
        case 1:
            return 'Low'
        case 2:
            return 'Medium'
        case 3:
            return 'High'
        case 4:
            return 'Very High'
        case 5:
            return 'Paused'
        default:
            return ''
    }
}