import {api} from "../../../../common/services/api";
import {AxiosResponse} from "axios";
import {
    INiche,
    ICustomDomainParams,
    ICustomDomain,
    ITestTrackingPixel,
    IFetchPixelGroupsResponse,
    ICreatePixelGroupParams,
    ICreateUpsellResponse,
    IBudgetGroups,
    IFetchCitiesResponse,
    IFetchContinentsResponse,
    IFetchCountryResponse,
    IFetchRegionsResponse,
    IFetchTrafficGroupsResponse,
    IFetchIspListResponse,
    ICreateBudgetGroupParams,
    IFetchCurrenciesResponse,
    IFetchAccountBalanceResponse
} from "./interfaces/interfaces";



class CreateOfferService {

    static createOffer = async (token: string, params: any) => {
        return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_OFFERS,
                params.id
            ].join("/"),
            {
                ...params,
                _method:"PATCH"
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            }
        )
    }

    static saveDraft = async (token: string, params: any) => {
        return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_OFFERS,
                'save-draft',
                params.id
            ].join("/"),
            {
                ...params
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            }
        )
    }

    static fetchDraft = async (token: string, id: any) => {
        return await api.get([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_OFFERS,
                'get-draft',
                id
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            }
        )
    }

    static fetchNiches = async (token: string) => {
        return await api.get<INiche[]>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "niches-campaign"
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
    }

    static fetchCustomDomains = async (token: string, params: ICustomDomainParams) => {
        // AxiosResponse set because request returns pagination structure
        return await api.get<AxiosResponse<ICustomDomain[]>>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "domains"
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params
            }
        )
    }
    static testTrackingPixel = async (token: string, campaign_id: number, time: number | boolean) => {
        return await api.post<ITestTrackingPixel>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "campaigns/test",
                campaign_id
            ].join("/"),
            {
                id: campaign_id,
                time: time
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            }
        )
    }

    static fetchCurrencies = async (token: string) => {

        return await api.get<IFetchCurrenciesResponse[]>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "currencies"
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            },)

    }
    
    static fetchBudgetGroups = async (token: string) => {
        return await api.get<IBudgetGroups[]>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_BUDGET_GROUPS
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    no_pagination: true
                }
            },)
    }
    
    static createBudgetGroup = async (token: string, params: ICreateBudgetGroupParams) => {
        return await api.post<IBudgetGroups>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_BUDGET_GROUPS
            ].join("/"),
            {
                ...params,
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
    }
    
    static fetchPixelGroups = async (token: string, group_type: 'upsell' | 'offer') => {
        
        return await api.get<IFetchPixelGroupsResponse[]>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "pixel-groups"
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    group_type,
                    no_pagination: true
                }
            },)
        
    }
    
    static fetchPixelGroup = async (token: string, id: number) => {
        return await api.get<IFetchPixelGroupsResponse>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "pixel-groups", id
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            },
        )
        
    }
    
    static createPixelGroup = async (token: string, params: ICreatePixelGroupParams) => {
        return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "pixel-groups"
            ].join("/"),
            {
                ...params,
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            }
        )
    }
    
    static editPixelGroup = async (token: string, id: number, params: IFetchPixelGroupsResponse) => {
        return await api.post<IFetchPixelGroupsResponse>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "pixel-groups", id
            ].join("/"),
            {
                ...params,
                _method: 'PATCH'
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            },
        )

    }
    
    static createUpsell = async (token: string, campaign_id: number) => {
        return await api.post<ICreateUpsellResponse>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "upsells"
            ].join("/"),
            {
                campaign_id,
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            }
        )
        
    }
    
    static fetchTrafficGroups = async (token: string) => {
        return await api.get<IFetchTrafficGroupsResponse[]>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_TRAFFIC_GROUPS,
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    with_types: true
                }
            },)
        
    }
    
    static fetchCountryState = async (token: string) => {
        
        return await api.get<IFetchCountryResponse>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "country-state"
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            },)
        
    }
    
    static fetchContinents = async (token: string) => {
        
        return await api.post<IFetchContinentsResponse[]>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "filters/continents"
            ].join("/"),
            {},
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
            ,)
        
    }
    
    static fetchRegions = async (token: string) => {
        
        return await api.post<IFetchRegionsResponse[]>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "filters/regions"
            ].join("/"),
            {},
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            },)
        
    }
    
    static fetchCities = async (token: string) => {
        
        return await api.get<IFetchCitiesResponse[]>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "cities"
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            },)
        
    }
    
    static fetchIspList = async (token: string, campaign_id: number) => {
        return await api.get<IFetchIspListResponse[]>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "isp_list"
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    campaign_id
                }
            },)
    }

    static fetchAccountBalance = async (token: string, id: number) => {

        return await api.get<IFetchAccountBalanceResponse>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "users/account_balance"
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    id
                }
            },)

    }
    
    static uploadZipcodes = async (token: string, campaign_id: number, file: File) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('campaign_id', campaign_id.toString())
        return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "zipcode_upload"
            ].join("/"),
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            },)
    }
    
    static deleteZipCode = async (token: string, campaign_id: number, fileName: string) => {
        return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "zipcode_delete"
            ].join("/"),
            {
                campaign_id,
                file: fileName
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            },)
    }
    
    static fetchEmptyOffer = async (token: string, detailed_pricing_model: string | null, pricing_model: string | null) => {
        return await api.get([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_OFFERS,
                'init',
                'empty'
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    detailed_pricing_model,
                    pricing_model
                }
            })
    }

    static fetchOffer = async (token: string, id: string | number) => {
        return await api.get([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_OFFERS,
                `${id}/edit`
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            },)
    }

    static fetchTrackingSystem = async (token: string, pricing_model: string) => {
        return await api.get([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_TRACKING_SYSTEMS,
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    pricing_model: pricing_model
                }
            })
    }

    static replicateCreatives = async (token: string, id_edit: number, id_new: number) => {
        return await api.get([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_OFFERS,
                `${id_edit}/creatives/replicate`
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    new_campaign_id: id_new
                }
            }
        )
    }

    static replicateUpsells = async (token: string, id_edit: number, id_new: number) => {
        return await api.get([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_OFFERS,
                `${id_edit}/upsells/replicate`
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    new_campaign_id: id_new
                }
            }
        )
    }

    static makeCreativePreview = async (token: string, campaign_id: number, creativeId: string | number, files: unknown[] | undefined) => {
        return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "file",
                'creative',
                'make_preview',
                `${campaign_id}`,
                `${creativeId}`
            ].join("/"),
            {
                files
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            },)
    }

    static getCampaignsInfo = async (token: string, budget_group_id: number) => {
        return await api.get([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_BUDGET_GROUPS,
                `get-campaigns-info/${budget_group_id}`
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }
    
}

export default CreateOfferService