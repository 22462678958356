import {
    CUSTOM,
    DatePreset,
    DatePresetType,
    LAST_7_DAYS,
    LAST_MONTH,
    LAST_QUARTER,
    LAST_WEEK,
    LAST_YEAR,
    MONTH_TO_DATE,
    MONTH_TO_YESTERDAY, PREVIOUS_FULL_YEAR,
    PREVIOUS_PERIOD,
    PREVIOUS_YEAR, PREVIOUS_YEAR_DOW,
    QUARTER_TO_DATE,
    THIS_MONTH,
    THIS_YEAR,
    TODAY,
    WEEK_TO_DATE,
    YEAR_TO_DATE,
    YEAR_TO_LAST_FULL_MONTH,
    YEAR_TO_YESTERDAY,
    YESTERDAY
} from "../models/datePresets";
import {
    addDays,
    endOfMonth, endOfQuarter, endOfYear, getISODay, getISOWeek, getYear,
    lastDayOfISOWeek, lastDayOfMonth,
    startOfISOWeek,
    startOfMonth,
    startOfQuarter, startOfYear,
    subDays,
    subMonths,
    subQuarters, subYears
} from "date-fns";
import {calculateDatePresetPreviousPeriod} from "./calculateDatePresetpreviousPeriod";
import {getWeekISO} from "./getWeekISO";

export const getDatePresetValues = (
    preset: DatePresetType,
    dateFrom?: Date,
    dateTo?: Date,
    valueForPreviousPeriod?: DatePresetType
) => {
    let from = dateFrom || new Date();
    let to = dateTo || new Date();
    switch (preset) {
        case TODAY :
            from = new Date()
            to = new Date()
            break;
        case YESTERDAY:
            from = subDays(new Date(Date.now()), 1)
            to = subDays(new Date(Date.now()), 1)
            break;
        case WEEK_TO_DATE:
            from = startOfISOWeek(new Date())
            to = new Date()
            break;
        case LAST_7_DAYS:
            from = subDays(new Date(Date.now()), 7)
            to = subDays(new Date(Date.now()), 1)
            break;
        case LAST_WEEK:
            from = startOfISOWeek(subDays(new Date(Date.now()), 7))
            to = lastDayOfISOWeek(subDays(new Date(Date.now()), 7))
            break;
        case MONTH_TO_DATE:
            from = startOfMonth(new Date())
            to = new Date()
            break;
        case THIS_MONTH:
            from = startOfMonth(new Date())
            to = subDays(new Date(Date.now()), 0)
            break;
        case MONTH_TO_YESTERDAY:
            from = startOfMonth(new Date())
            to = subDays(new Date(Date.now()), 1)
            break;
        case LAST_MONTH:
            from = startOfMonth(subMonths(new Date(), 1))
            to = endOfMonth(subMonths(new Date(), 1))
            break;
        case QUARTER_TO_DATE:
            from = startOfQuarter(new Date())
            to = new Date()
            break;
        case LAST_QUARTER:
            from = startOfQuarter(subQuarters(new Date(),1))
            to = endOfQuarter(subQuarters(new Date(),1))
            break;
        case YEAR_TO_YESTERDAY:
            from = startOfYear(new Date())
            to = subDays(new Date(Date.now()), 1)
            break;
        case YEAR_TO_LAST_FULL_MONTH:
            from = startOfYear(new Date())
            to = lastDayOfMonth(subMonths(new Date(), 1))
            break;
        case LAST_YEAR:
            from = startOfYear(subYears(new Date(), 1))
            to = endOfYear(subYears(new Date(), 1))
            break;
        case YEAR_TO_DATE:
            from = startOfYear(new Date())
            to = new Date()
            break;
        case THIS_YEAR:
            from = startOfYear(new Date())
            to = new Date()
            break;
        case PREVIOUS_YEAR:
            if(dateFrom && dateTo) {
                from = subYears(new Date(dateFrom), 1)
                to = subYears(new Date(dateTo), 1)
            }
            break;
        case PREVIOUS_PERIOD:
            if(valueForPreviousPeriod){
                const {from: fromPrevious, to: toPrevious} = calculateDatePresetPreviousPeriod(valueForPreviousPeriod, from, to)
                from = fromPrevious
                to = toPrevious
            }
            break;
        case PREVIOUS_YEAR_DOW:
            if(dateFrom && dateTo){
                let fromWeek = getISOWeek(new Date(dateFrom))
                let untilWeek = getISOWeek(new Date(dateTo))
                let fromDay = getISODay(new Date(dateFrom))
                let untilDay = getISODay(new Date(dateTo))
                let year = getYear(new Date(dateFrom))

                from = addDays(getWeekISO(fromWeek, year -1), fromDay - 1)
                to = addDays(getWeekISO(untilWeek, year -1), untilDay - 1)

            }
            break;
        case PREVIOUS_FULL_YEAR:
            if(dateFrom && dateTo){
                from = startOfYear(subYears(new Date(dateFrom), 1))
                to = endOfYear(subYears(new Date(dateTo), 1))
            }
            break;
        case CUSTOM:
            // const [monthFrom, dayFrom, yearFrom] = tableAndDateData.input_from_date.split('/');
            // const [monthTo, dayTo, yearTo] = tableAndDateData.input_until_date.split('/');
            // from = new Date(+yearFrom, +monthFrom - 1, +dayFrom )
            // to = new Date(+yearTo, +monthTo - 1, +dayTo)
            break;
    }

    return {
        dateFrom: from,
        dateTo: to,
        previousPeriod: (previousPreset: DatePresetType) =>  calculateDatePresetPreviousPeriod(previousPreset, from, to),
    }
}