import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ColumnState} from "ag-grid-community";


export interface ITableSliceInitialState {
    [key: string] : ColumnState[]
}

const initialState : ITableSliceInitialState ={

}

const tableSlice = createSlice({
    name: 'tables',
    initialState,
    reducers: {
        setTableState :(
            state : ITableSliceInitialState,
            action: PayloadAction<{
                id: string,
                columnState : ColumnState[]
            }>
        ) => {
            state[action.payload.id] = action.payload.columnState
        }
    }
})

export default tableSlice.reducer

export const {
    setTableState
} = tableSlice.actions
