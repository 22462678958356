import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface IDashboardWidgetPaidMediaState {
    page: number,
    perPage: number
}


interface InitState {
    breadcrumb: string;
    isAccountMenuDisplayed : boolean;
    isSupportMenuDisplayed : boolean;
    dashboardWidgetPaidMedia: IDashboardWidgetPaidMediaState
}

const initialState : InitState = {
    breadcrumb : "Dashboard",
    isAccountMenuDisplayed : false,
    isSupportMenuDisplayed : false,
    dashboardWidgetPaidMedia: {
        page: 1,
        perPage: 5,
    },
}

const agencyMarketerSlice = createSlice({
    name: "agencyMarketer",
    initialState,
    reducers :{
        setBreadCrumb : (state: InitState, action : PayloadAction<string>) =>{
            state.breadcrumb = action.payload
        },
        setAccountMenuDisplayed: (state :InitState , action : PayloadAction<boolean>) =>{
            state.isAccountMenuDisplayed = action.payload
        },
        setSupportMenuDisplayed: (state :InitState , action : PayloadAction<boolean>) =>{
            state.isSupportMenuDisplayed = action.payload
        },
        setDashboardWidgetPaidMediaState: (state: InitState, action: PayloadAction<Partial<IDashboardWidgetPaidMediaState>>) => {
            state.dashboardWidgetPaidMedia = {...state.dashboardWidgetPaidMedia, ...action.payload}
        },
    }
})

export default agencyMarketerSlice.reducer
export const { 
    setBreadCrumb, 
    setAccountMenuDisplayed, 
    setSupportMenuDisplayed,
    setDashboardWidgetPaidMediaState 
} = agencyMarketerSlice.actions