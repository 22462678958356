export const addMacroToUrl = (url: string, macro: string): string => {
    let newMacro = macro
    let newUrl = ''

    if(newMacro.charAt(0) === '&'){
        newMacro = newMacro.replace('&', '')
    }


    if(url.includes('?')){
        newUrl = url + '&' + newMacro
    } else {
        newUrl = url + '?' + newMacro
    }

    return newUrl.replaceAll('?&', '?').replaceAll('&&', '&')
}