import {PageID} from "../../../store/filters.slice";
import {FilterID} from "filters";
import {IMetricOptions, IPreset} from "../../../common/components/customize-report/interfaces";
import {
    DatePresetType,
    LAST_7_DAYS,
    LAST_MONTH,
    LAST_QUARTER,
    LAST_WEEK, LAST_YEAR,
    MONTH_TO_YESTERDAY,
    PREVIOUS_FULL_YEAR,
    PREVIOUS_PERIOD,
    PREVIOUS_YEAR,
    PREVIOUS_YEAR_DOW,
    QUARTER_TO_DATE,
    THIS_MONTH,
    THIS_YEAR,
    TODAY,
    WEEK_TO_DATE, YEAR_TO_DATE,
    YEAR_TO_LAST_FULL_MONTH,
    YEAR_TO_YESTERDAY,
    YESTERDAY
} from "../../../common/models/datePresets";

export const PAGE_ID: PageID = "agency/dynamic-report"
export const QUERY_NAME = "agency-marketers-dynamic-report"
export const QUERY_NAME_SUMS = "agency-marketers-dynamic-report-sums"
export const QUERY_FILTER_PRESETS = "agency-marketers-dynamic-report-presets"

export enum ColumnsAndFilters {
    AOV = "aov",
    BROWSER = "group_by_flex_browser",
    CAC = "cac",
    CAMPAIGN_ID = "group_by_campaign_id",
    CAMPAIGN_NAME = "group_by_campaign_name",
    CLICKS = "total_clicks",
    COMMISSION_APPROVED = "cost",
    COMMISSION_TOTAL = "partner_commission_grosss",
    CONVERSIONS_APPROVED = "approved_conversions",
    CONVERSIONS_TOTAL = "total_conversions",
    COUNTRY = "group_by_flex_country_code",
    CPC = "cpc",
    CREATIVE_ID = "group_by_flex_creative_id",
    CREATIVE_NAME = "group_by_link_flex_creative_name",
    CREATIVE_TYPE = "group_by_creative_type",
    CUSTOMER_TYPE = "group_by_customer_type",
    CVR = "cvr",
    DATE = "group_by_date_day_timestamp",
    DATE_HOUR = "group_by_date_hourly_timestamp",
    DEVICE_NAME = "group_by_flex_device_name",
    DEVICE_TYPE = "group_by_flex_device_type",
    DISPOSITION = "group_by_date_disposition",
    ECPA = "ecpa",
    EPC = "epc",
    EVENT_ID = "group_by_upsell_id",
    EVENT_NAME = "group_by_upsell_name",
    LINK_ID = "group_by_link_id",
    LINK_NAME = "group_by_link_name",
    MONTH = "group_by_date_month_timestamp",
    OS = "group_by_flex_os",
    PARTNER_BONUSES = "partner_bonusess",
    PARTNER_COMPANY_NAME = "group_by_partner_company",
    PARTNER_TRAFFIC_TYPE = "group_by_partner_traffic_type",
    PLATFORM_FEE_APPROVED = "platform_fees",
    PLATFORM_FEE_TOTAL = "platform_fee_grosss",
    PROMO_CODE = "group_by_promo_code",
    ROAS = "roas",
    SALE_AMOUNT = "revenue_amount",
    SPEND_APPROVED = "valid_revenue",
    SPEND_TOTAL = "total_revenue",
    TRANSACTION_ID = "group_by_transaction_id",
    WEEK = "group_by_week_timestamp",
}

/**
 * this list represents names of the columns and data returned from the API
 * table will be sorted by these names
 */
export enum ColumnIDS  {
    AOV = "aov",
    BROWSER = "browser",
    CAC = "cac",
    CAMPAIGN_ID = "offer_id",
    CAMPAIGN_NAME = "campaign_name",
    CLICKS = "clicks",
    COMMISSION_APPROVED = "cost",
    COMMISSION_TOTAL = "partner_commission_grosss",
    CONVERSIONS_APPROVED = "approved_conversions",
    CONVERSIONS_TOTAL = "total_conversions",
    COUNTRY = "country_code",
    CPC = "cpc",
    CREATIVE_ID = "creative_id",
    CREATIVE_NAME = "creative_name",
    CREATIVE_TYPE = "creative_type",
    CUSTOMER_TYPE = "new_customer",
    CVR = "cvr",
    DATE = "date_day_timestamp",
    DATE_HOUR = "hourly_timestamp",
    DEVICE_NAME = "device_name",
    DEVICE_TYPE = "device_type",
    DISPOSITION = "disposition",
    ECPA = "ecpa",
    EPC = "epc",
    EVENT_ID = "event_id",
    EVENT_NAME = "upsell_name",
    LINK_ID = "link_id",
    LINK_NAME = "link_name",
    MONTH = "date_month_timestamp",
    OS = "os",
    PARTNER_BONUSES = "partner_bonusess",
    PARTNER_COMPANY_NAME = "affiliate_company",
    PARTNER_TRAFFIC_TYPE = "partner_traffic_type",
    PLATFORM_FEE_APPROVED = "platform_fees",
    PLATFORM_FEE_TOTAL = "platform_fee_grosss",
    PROMO_CODE = "promo_code",
    ROAS = "roas",
    SALE_AMOUNT = "revenue_amount",
    SPEND_APPROVED = "valid_revenue",
    SPEND_TOTAL = "total_revenue",
    TRANSACTION_ID = "transaction_id",
    WEEK = "date_week_timestamp",
}

export const ColumnsAndFiltersMap: Map<ColumnsAndFilters, IMetricOptions> = new Map<ColumnsAndFilters, IMetricOptions>([
        [ColumnsAndFilters.AOV, {
            id: ColumnIDS.AOV,
            columnId: 'aov',
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "AOV",
            disable: false,
            tooltip: "The average order value per sale. The calculation used to determine this: Sale Amount / " +
                "Conversions Approved",
        }],
        [ColumnsAndFilters.BROWSER, {
            id: ColumnsAndFilters.BROWSER,
            columnId: ColumnIDS.BROWSER,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "Browser",
            disable: false,
            tooltip: "The browser type used by the customer when completing a click, conversion or event. ",
        }],
        [ColumnsAndFilters.CAC, {
            id: ColumnsAndFilters.CAC,
            columnId: ColumnIDS.CAC,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "CAC",
            disable: false,
            tooltip: "The customer acquistion cost is the average amount spent for a new customer. The calculation used to " +
                "determine this: Spend Approved / (Count of Conversions Approved when customer Type = New). ",
        }],
        [ColumnsAndFilters.CAMPAIGN_ID, {
            id: ColumnsAndFilters.CAMPAIGN_ID,
            columnId: ColumnIDS.CAMPAIGN_ID,
            filterId: 'campaigns',
            isFilter: true,
            isColumn: true,
            name: "Campaign ID",
            disable: false,
            tooltip: "The unique identification number assigned to an campaign.",
        }],
        [ColumnsAndFilters.CAMPAIGN_NAME, {
            id: ColumnsAndFilters.CAMPAIGN_NAME,
            columnId: ColumnIDS.CAMPAIGN_NAME,
            filterId: 'campaigns',
            isFilter: true,
            isColumn: true,
            name: "Campaign Name",
            disable: false,
            tooltip: "The campaign name will show through to partners.",
        }],
        [ColumnsAndFilters.CLICKS, {
            id: ColumnsAndFilters.CLICKS,
            columnId: ColumnIDS.CLICKS,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "Clicks",
            disable: false,
            tooltip: "The number of times a customer clicked on an ad and was directed to an intended campaign.",
        }],
        [ColumnsAndFilters.COMMISSION_APPROVED, {
            id: ColumnsAndFilters.COMMISSION_APPROVED,
            columnId: ColumnIDS.COMMISSION_APPROVED,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "Commission Approved",
            disable: false,
            tooltip: "The amount the partner is paid for total traffic.",
        }],
        [ColumnsAndFilters.COMMISSION_TOTAL, {
            id: ColumnsAndFilters.COMMISSION_TOTAL,
            columnId: ColumnIDS.COMMISSION_TOTAL,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "Commission Total",
            disable: false,
            tooltip: "The amount the partner is paid.",
        }],
        [ColumnsAndFilters.CONVERSIONS_APPROVED, {
            id: ColumnsAndFilters.CONVERSIONS_APPROVED,
            columnId: ColumnIDS.CONVERSIONS_APPROVED,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "Conversions Approved",
            disable: false,
            tooltip: "The number of payable conversions to the partner.  Displayed as conversions in Partner UI.",
        }],
        [ColumnsAndFilters.CONVERSIONS_TOTAL, {
            id: ColumnsAndFilters.CONVERSIONS_TOTAL,
            columnId: ColumnIDS.CONVERSIONS_TOTAL,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "Conversions Total",
            disable: false,
            tooltip: "The number of valid & gross rejected conversions. ",
        }],
        [ColumnsAndFilters.COUNTRY, {
            id: ColumnsAndFilters.COUNTRY,
            columnId: ColumnIDS.COUNTRY,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "Country",
            disable: false,
            tooltip: "Where the customer was located when completing a click, conversion or event.",
        }],
        [ColumnsAndFilters.CPC, {
            id: ColumnsAndFilters.CPC,
            columnId: ColumnIDS.CPC,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "CPC",
            disable: false,
            tooltip: "The average cost per click, paid to the partner. The calculation used to determine this: Commission Approved / Clicks",
        }],
        [ColumnsAndFilters.CREATIVE_ID, {
            id: ColumnsAndFilters.CREATIVE_ID,
            columnId: ColumnIDS.CREATIVE_ID,
            filterId: 'creative-name',
            isFilter: true,
            isColumn: true,
            name: "Creative ID",
            disable: false,
            tooltip: "A value passed from the partner's tracking platform on the click.",
        }],
        [ColumnsAndFilters.CREATIVE_NAME, {
            id: ColumnsAndFilters.CREATIVE_NAME,
            columnId: ColumnIDS.CREATIVE_NAME,
            filterId: 'creative-name',
            isFilter: true,
            isColumn: true,
            name: "Creative Name",
            disable: false,
            tooltip: "A value passed from the partner's tracking platform on the click.",
        }],
        [ColumnsAndFilters.CREATIVE_TYPE, {
            id: ColumnsAndFilters.CREATIVE_TYPE,
            columnId: ColumnIDS.CREATIVE_TYPE,
            filterId: 'creative-type',
            isFilter: true,
            isColumn: true,
            name: "Creative Type",
            disable: false,
            tooltip: "The type assigned to a creative: text, banner, etc.",
        }],
        [ColumnsAndFilters.CUSTOMER_TYPE, {
            id: ColumnsAndFilters.CUSTOMER_TYPE,
            columnId: ColumnIDS.CUSTOMER_TYPE,
            filterId: 'customer-type',
            isFilter: true,
            isColumn: true,
            name: "Customer Type",
            disable: false,
            tooltip: "Indicates whether the customer was a new or existing customer.",
        }],
        [ColumnsAndFilters.CVR, {
            id: ColumnsAndFilters.CVR,
            columnId: ColumnIDS.CVR,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "CVR",
            disable: false,
            tooltip: "The conversion rate is the percent of clicks that convert to a conversion. " +
                "The calculation used to determine this: Conversions Approved / Clicks",
        }],
        [ColumnsAndFilters.DATE, {
            id: ColumnsAndFilters.DATE,
            columnId: ColumnIDS.DATE,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "Date",
            disable: false,
            tooltip: "The date of the click, conversion or event. This is in EST timezone.",
        }],
        [ColumnsAndFilters.DATE_HOUR, {
            id: ColumnsAndFilters.DATE_HOUR,
            columnId: ColumnIDS.DATE_HOUR,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: "Date/Hour",
            disable: false,
            tooltip: "The conversion rate is the percent of clicks that convert to a conversion. " +
                "The calculation used to determine this: Conversions Approved / Clicks",
        }],
        [ColumnsAndFilters.DEVICE_NAME, {
            id: ColumnsAndFilters.DEVICE_NAME,
            columnId: ColumnIDS.DEVICE_NAME,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'Device Name',
            disable: false,
            tooltip: 'The specific device model used by the customer when when completing a click, conversion or event. ',
        }],
        [ColumnsAndFilters.DEVICE_TYPE, {
            id: ColumnsAndFilters.DEVICE_TYPE,
            columnId: ColumnIDS.DEVICE_TYPE,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'Device Type',
            disable: false,
            tooltip: 'The type of device used by the customer when when completing a click, conversion or event. ',
        }],
        [ColumnsAndFilters.DISPOSITION, {
            id: ColumnsAndFilters.DISPOSITION,
            columnId: ColumnIDS.DISPOSITION,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'Disposition',
            disable: false,
            tooltip: 'The platform\'s approval status of each click, conversion and event. ',
        }],
        [ColumnsAndFilters.ECPA, {
            id: ColumnsAndFilters.ECPA,
            columnId: ColumnIDS.ECPA,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'ECPA',
            disable: false,
            tooltip: "Effective CPA is the average amount spent for each action. The calculation used to determine this: Spend Approved / Conversions Approved ",
        }],
        [ColumnsAndFilters.EPC, {
            id: ColumnsAndFilters.EPC,
            columnId: ColumnIDS.EPC,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'EPC',
            disable: false,
            tooltip: "The average earnings per click. The calculation used to determine this: Spend Approved / Clicks"
        }],
        [ColumnsAndFilters.EVENT_ID, {
            id: ColumnsAndFilters.EVENT_ID,
            columnId: ColumnIDS.EVENT_ID,
            filterId: 'events',
            isFilter: true,
            isColumn: true,
            name: 'Event ID',
            disable: false,
            tooltip: "The unique identification number assigned to an event.",
        }],
        [ColumnsAndFilters.EVENT_NAME, {
            id: ColumnsAndFilters.EVENT_NAME,
            columnId: ColumnIDS.EVENT_NAME,
            filterId: 'events',
            isFilter: true,
            isColumn: true,
            name: 'Event Name',
            disable: false,
            tooltip: 'The name added to the event when creating the campaign. ',
        }],
        [ColumnsAndFilters.LINK_ID, {
            id: ColumnsAndFilters.LINK_ID,
            columnId: ColumnIDS.LINK_ID,
            filterId: 'links',
            isFilter: true,
            isColumn: true,
            name: 'Link ID',
            disable: false,
            tooltip: 'The unique identification number assigned to a link.',
        }],
        [ColumnsAndFilters.LINK_NAME, {
            id: ColumnsAndFilters.LINK_NAME,
            columnId: ColumnIDS.LINK_NAME,
            filterId: 'links',
            isFilter: true,
            isColumn: true,
            name: 'Link Name',
            disable: false,
            tooltip: 'The link name assigned by the partner(or partner rep) when creating a link.',
        }],
        [ColumnsAndFilters.MONTH, {
            id: ColumnsAndFilters.MONTH,
            columnId: ColumnIDS.MONTH,
            filterId: '',
            name: 'Month',
            isFilter: false,
            isColumn: true,
            disable: false,
            tooltip: 'The month the click, conversion or event occured.  This is in EST timezone. ',
        }],
        [ColumnsAndFilters.OS, {
            id: ColumnsAndFilters.OS,
            columnId: ColumnIDS.OS,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'OS',
            disable: false,
            tooltip: 'The device operating system used by the customer when completing a click, conversion or event.',
        }],
        [ColumnsAndFilters.PARTNER_BONUSES, {
            id: ColumnsAndFilters.PARTNER_BONUSES,
            columnId: ColumnIDS.PARTNER_BONUSES,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'Partner Bonuses',
            disable: false,
            tooltip: "The amount the partner is paid separately from commission on transactions. This only applies for some external platforms.",
        }],
        [ColumnsAndFilters.PARTNER_COMPANY_NAME, {
            id: ColumnsAndFilters.PARTNER_COMPANY_NAME,
            columnId: ColumnIDS.PARTNER_COMPANY_NAME,
            filterId: 'partner-company-name',
            isFilter: true,
            isColumn: true,
            name: 'Partner Company Name',
            disable: false,
            tooltip: 'The company name that this partner is associated with.',
        }],
        [ColumnsAndFilters.PARTNER_TRAFFIC_TYPE, {
            id: ColumnsAndFilters.PARTNER_TRAFFIC_TYPE,
            columnId: ColumnIDS.PARTNER_TRAFFIC_TYPE,
            filterId: 'partner-traffic-type',
            isFilter: true,
            isColumn: true,
            name: 'Partner Traffic Type',
            disable: false,
            tooltip: "The main type of traffic served by a partner and assigned by the Perform[cb] team.",
        }],
        [ColumnsAndFilters.PLATFORM_FEE_APPROVED, {
            id: ColumnsAndFilters.PLATFORM_FEE_APPROVED,
            columnId: ColumnIDS.PLATFORM_FEE_APPROVED,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'Platform Fee Approved',
            disable: false,
            tooltip: "The amount spent on fees to the external tracking platform for approved traffic."
        }],
        [ColumnsAndFilters.PLATFORM_FEE_TOTAL, {
            id: ColumnsAndFilters.PLATFORM_FEE_TOTAL,
            columnId: ColumnIDS.PLATFORM_FEE_TOTAL,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'Platform Fee Total',
            disable: false,
            tooltip: "The amount spent on fees to the external tracking platform for total traffic."
        }],
        [ColumnsAndFilters.PROMO_CODE, {
            id: ColumnsAndFilters.PROMO_CODE,
            columnId: ColumnIDS.PROMO_CODE,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'Promo Code',
            disable: false,
            tooltip: "The unique code used by the customer when completing the transaction on the website or app.",
        }],
        [ColumnsAndFilters.ROAS, {
            id: ColumnsAndFilters.ROAS,
            columnId: ColumnIDS.ROAS,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'ROAS',
            disable: false,
            tooltip: "The amount of sales earned for every dollar spend on advertising. \nThe calculation used to determine this: Sale Amount / Spend Approved"
        }],
        [ColumnsAndFilters.SALE_AMOUNT, {
            id: ColumnsAndFilters.SALE_AMOUNT,
            columnId: ColumnIDS.SALE_AMOUNT,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'Sale Amount',
            disable: false,
            tooltip: "The amount made in sales.",
        }],
        [ColumnsAndFilters.SPEND_APPROVED, {
            id: ColumnsAndFilters.SPEND_APPROVED,
            columnId: ColumnIDS.SPEND_APPROVED,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'Spend Approved',
            disable: false,
            tooltip: "The amount spent on advertising for approved traffic. \n Includes commission approved, partner bonuses and platform fees.",
        }],
        [ColumnsAndFilters.SPEND_TOTAL, {
            id: ColumnsAndFilters.SPEND_TOTAL,
            columnId: ColumnIDS.SPEND_TOTAL,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'Spend Total',
            disable: false,
            tooltip: "The amount spent on advertising for approved traffic. \n Includes commission approved, partner bonuses and platform fees.",
        }],
        [ColumnsAndFilters.TRANSACTION_ID, {
            id: ColumnsAndFilters.TRANSACTION_ID,
            columnId: ColumnIDS.TRANSACTION_ID,
            filterId: '',
            isFilter: false,
            isColumn: true,
            name: 'Transaction ID',
            disable: false,
            tooltip: "The unique identification number assigned to the customer transaction.",
        }],
        [ColumnsAndFilters.WEEK, {
            id: ColumnsAndFilters.WEEK,
            columnId: ColumnIDS.WEEK,
            filterId: '',
            name: 'Week',
            isColumn: true,
            isFilter: false,
            disable: false,
            tooltip: 'The week of the click, conversion or event. This is in EST timezone. ',
        }],
    ]
)

export const ALL_FILTERS: {
    id: FilterID,
    value: string
}[] = [
    {
        id: 'creative-type',
        value: 'Creative Type'
    },
    {
        id: 'customer-type',
        value: 'Customer Type'
    },
    {
        id: 'partner-company-name',
        value: 'Partner Company Name'
    },
    {
        id: 'partner-traffic-type',
        value: 'Partner Traffic Type'
    },
    {
        id: 'campaigns',
        value: 'Campaign'
    },
    {
        id: 'creative-name',
        value: 'Creative'
    },
    {
        id: 'events',
        value: 'Event'
    },
    {
        id: 'links',
        value: 'Link'
    }
]

export const  BASIC_PRESET : IPreset = {
    type: "agency",
    preset_name: "Analyze By Month",
    isDefault: true,
    selected_column_ids: [
        ColumnsAndFilters.MONTH,
        ColumnsAndFilters.SPEND_APPROVED,
        ColumnsAndFilters.SALE_AMOUNT,
        ColumnsAndFilters.ROAS,
        ColumnsAndFilters.CONVERSIONS_APPROVED,
        ColumnsAndFilters.CLICKS,
        ColumnsAndFilters.CVR,
        ColumnsAndFilters.EPC,
        ColumnsAndFilters.AOV,
        ColumnsAndFilters.ECPA,
        ColumnsAndFilters.CAC
    ],
    filters: [
        {
            id: "campaigns",
            exclude: false,
            selected: [

            ]
        },
        {
            id: "events",
            exclude: true,
            selected: [

            ]
        },
        {
            id: "partner-company-name",
            exclude: false,
            selected: [

            ]
        },
        {
            id: "partner-traffic-type",
            exclude: false,
            selected: [

            ]
        }
    ],
    paginate_by : 50,
    page: 1,
    sort_by : ColumnsAndFilters.SPEND_APPROVED,
    order: "desc",
    isCompareActive: false,
    sums: false,
    dateRange: {
        preset: YEAR_TO_DATE,
        from: new Date(),
        to: new Date
    },
    compareDateRange: null
}


export const FilterMapping = new Map<string, string>(
    [
        ['creative-name', 'creative_name'],
        ['events', 'upsells'],
        ['customer-type', 'customer_type'],
        ['creative-type', 'creative_type'],
        ['links', 'links'],
        ['partner-company-name', 'partner_company_name' ],
        ['partner-traffic-type', 'partner_traffic_types']
    ]
)

export const REGULAR_PRESETS: DatePresetType[] = [
    TODAY,
    YESTERDAY,
    WEEK_TO_DATE,
    LAST_7_DAYS,
    LAST_WEEK,
    THIS_MONTH,
    MONTH_TO_YESTERDAY,
    LAST_MONTH,
    QUARTER_TO_DATE,
    LAST_QUARTER,
    THIS_YEAR,
    YEAR_TO_YESTERDAY,
    YEAR_TO_LAST_FULL_MONTH,
    LAST_YEAR,
]

export const COMPARE_PRESETS: DatePresetType[] = [
    PREVIOUS_PERIOD ,
    PREVIOUS_YEAR ,
    PREVIOUS_YEAR_DOW ,
    PREVIOUS_FULL_YEAR
]
