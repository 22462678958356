import {createGlobalStyle} from "styled-components";

export default createGlobalStyle`
    .react-datepicker {
        background-color:transparent!important;
    }
    
    .react-datepicker__input-container {
        .date-value {
            .placeholder {
                color: ${({theme}) => theme.colors.coolGray[4]};
            }

            &:hover {
                border-color: ${({theme}) => theme.colors.coolGray[5]};
            }
        }

        .calendar-icon-container {
            &:hover {
                background: ${({theme}) => theme.colors.coolGray[2]};
            }
        }
    }

    &.datepicker-disabled {
        .react-datepicker__input-container {
            pointer-events: none;

            .date-value {
                background: ${({theme}) => theme.colors.coolGray[1]};
            }

            .calendar-icon-container {
                color: ${({theme}) => theme.colors.coolGray[4]};
                background: ${({theme}) => theme.colors.coolGray[1]};
            }
        }
    }

    .today {
        position: relative;
    }

    .today::after {
        content: '';
        width: 3px;
        height: 3px;
        border-radius: 50%;
        position: absolute;
        top: 3px;
        background-color: ${({theme}) => theme.colors.performOrange[6]};
    }
    
    //Datepicker dropdown
    #date-picker-portal {
        .react-datepicker-popper {
           z-index: 999999;
           padding-top: 1px;

           .react-datepicker {
               border: 0px;
               max-width: 212px;

               .react-datepicker__triangle {
                   display: none;
                   margin-top: 0px;
               }

               .react-datepicker__month-container, .react-datepicker__year--container {
                   display: flex;
                   flex-direction: column;
                   gap: 0;
                   max-width: 212px;
                   border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
                   box-sizing: border-box;
                   box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
                   border-radius: 16px;
                   background-color: #fff;
                   padding: 21px;

                   .react-datepicker__month-text--today {
                       position: relative;
                   }

                   .react-datepicker__month-text--today::after {
                       content: '';
                       width: 3px;
                       height: 3px;
                       border-radius: 50%;
                       position: absolute;
                       top: 3px;
                       background-color: ${({theme}) => theme.colors.performOrange[6]};
                   }


                   .react-datepicker__month-wrapper {
                       display: flex;
                       justify-content: space-between;
                       align-items: center;
                       height: 25px;

                       .react-datepicker__month--disabled {
                           color: #C6D2E2 !important;
                       }
                   }

                   .react-datepicker__monthPicker {
                       margin: 0;
                       width: 100%;

                       .react-datepicker__month-text, .react-datepicker__month--selected {
                           padding: 6px 4px;
                           display: inline-flex;
                           align-items: center;
                           justify-content: center;
                           border-radius: 4px;
                           width: 32px;
                           height: 24px;
                           font-size: 12px;
                           line-height: 16px;
                           font-weight: normal;
                           color: ${({theme}) => theme.colors.deepBlue[4]};

                           :hover {
                               background-color: ${({theme}) => theme.colors.performBlue[1]};
                           }

                           :focus {
                               outline-color: transparent;
                               outline-style: none;
                           }

                       }

                       .react-datepicker__month--selected {
                           background-color: ${({theme}) => theme.colors.performBlue[5]};
                           color: white;

                           :hover {
                               background-color: ${({theme}) => theme.colors.performBlue[5]};
                           }

                       }

                   }


                   .react-datepicker__header, .react-datepicker-year-header {
                       background-color: #fff;
                       border-bottom: 0px;
                       padding: 0;
                       font-weight: normal;
                       display: flex;
                       flex-direction: column;


                       .react-datepicker__current-month {
                           width: max-content;
                           height: 14px;
                           font-size: 12px;
                           margin: 0 auto;
                           color: #3E5371;
                           font-weight: 300;
                       }

                       .react-datepicker__day-names {
                           height: 25px;
                           margin: 0 auto;
                           display: flex;
                           justify-content: space-between;

                           .react-datepicker__day-name {
                               height: 16px;
                               width: 26px;
                               font-size: 12px;
                               margin: 0;
                               color: ${({theme}) => theme.colors.deepBlue[5]};
                               font-family: ${({theme}) => theme.fonts.AvenirRoman};
                           }
                       }
                   }


                   .react-datepicker__month, .react-datepicker__year {
                       /* height: 155px; */
                       display: flex;
                       flex-direction: column;
                       margin: 0;

                       .react-datepicker__week {
                           display: flex;
                           width: 100%;
                           justify-content: space-between;
                           align-items: center;
                           margin-bottom: 4px;
                           overflow: hidden;
                           border-radius: 4px;


                           .react-datepicker__day--today {
                               font-weight: normal;
                           }

                           .react-datepicker__day, .react-datepicker__day--keyboard-selected {
                               background-color: #fff;
                               color: ${({theme}) => theme.colors.deepBlue[4]};
                               font-size: 12px;
                               font-family: ${({theme}) => theme.fonts.AvenirRoman};
                               width: 26px;
                               height: 24px;
                               display: flex;
                               justify-content: space-around;
                               align-items: center;
                               line-height: 13px;
                               letter-spacing: 0.117857px;
                               border-radius: 4px;
                               margin: 0;

                               :focus-visible {
                                   outline: none;
                               }

                               :focus {
                                   outline-color: transparent;
                                   outline-style: none;
                               }
                           }

                           .react-datepicker__day:hover {
                               background: ${({theme}) => theme.colors.performBlue[1]};
                               transition: all .2s ease-in-out;
                           }

                           .react-datepicker__day--outside-month {
                               visibility: hidden;

                           }

                           .react-datepicker__day--selected, .react-datepicker__day--selected:hover {
                               background: ${({theme}) => theme.colors.performBlue[5]};
                               color: #fff;
                               transition: all 0.2s ease-in-out;
                           }

                           .react-datepicker__day--disabled, .react-datepicker__day--disabled:hover {
                               color: #C6D2E2;
                               background: #ffffff;
                           }
                       }
                   }
               }

               .react-datepicker__year--container {


               }

               .react-datepicker__year {
                   margin: 0;

                   .react-datepicker__year-wrapper {

                       width: 165px;
                       justify-content: space-between;

                       .react-datepicker__year-text, .react-datepicker__year-text--selected {
                           width: 40px;
                           height: 24px;
                           display: flex;
                           align-items: center;
                           justify-content: center;
                           margin: 0;
                           font-size: 12px;
                           color: ${({theme}) => theme.colors.deepBlue[4]};
                           border-radius: 4px;
                           color: ${({theme}) => theme.colors.deepBlue[4]};
                           font-weight: normal;

                           :hover {
                               background-color: ${({theme}) => theme.colors.performBlue[1]};
                           }

                           :focus {
                               outline-color: transparent;
                               outline-style: none;
                           }

                       }

                       .react-datepicker__year-text--selected {
                           color: white;

                           background-color: ${({theme}) => theme.colors.performBlue[5]};

                           :hover {
                               background-color: ${({theme}) => theme.colors.performBlue[5]};
                           }
                       }

                       .react-datepicker__year-text--today {
                           position: relative;
                       }

                       .react-datepicker__year-text--today::after {
                           content: '';
                           width: 3px;
                           height: 3px;
                           border-radius: 50%;
                           position: absolute;
                           top: 2px;
                           background-color: ${({theme}) => theme.colors.performOrange[6]};
                       }

                       .react-datepicker__year-text--disabled {
                           color: #C6D2E2 !important;

                           :hover {
                               cursor: default !important;
                               background-color: inherit !important;
                           }
                       }
                   }
               }
           }

           .react-datepicker__day--keyboard-selected,
           .react-datepicker__month-text--keyboard-selected,
           .react-datepicker__quarter-text--keyboard-selected,
           .react-datepicker__year-text--keyboard-selected {
               background: none;
           }
        }
    }
`