import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ALL, DatePresetType} from "../../../../common/models/datePresets";
import {IPreset} from "../../../../common/components/range-date-picker/presets/presets.props";

export interface IDateWithPreset {
    preset : DatePresetType,
    from : Date | null,
    to: Date | null,
}

export interface IDatePicker {
    dateRange: IDateWithPreset,
}

export interface IInitialState  {
    // New Way (not used yet)
    datePicker: IDatePicker,
    // Old Way
    date_from: Date | null | undefined,
    date_to: Date | null | undefined,
    date_range_preset: IPreset,
    per_page: number,
}

const initialState : IInitialState = {
    // New Way (not used yet)
    datePicker: {
        dateRange: {
            preset: ALL,
            from: null,
            to: null,
        }
    },
    // Old Way
    date_from: null,
    date_to: null,
    date_range_preset: {
        value: ALL,
        name: "All"
    },
    per_page: 50,
}


const PartnersRegistrationSlice = createSlice({
    name: 'PartnersRegistrationSlice',
    initialState,
    reducers: {
        setDateRange : (state : IInitialState, action : PayloadAction<IDateWithPreset>) => {
            state.datePicker.dateRange = {...action.payload}
        },
        setDateRangePreset : (state : IInitialState, action : PayloadAction<IPreset>) => {
            state.date_range_preset = action.payload
        },
        setDateFrom : (state : IInitialState, action : PayloadAction<Date | null | undefined>) => {
            state.date_from = action.payload
        },
        setDateTo : (state : IInitialState, action : PayloadAction<Date | null | undefined>) => {
            state.date_to = action.payload
        }
    }
})


export const {
    setDateRange,
    setDateRangePreset,
    setDateFrom,
    setDateTo
} = PartnersRegistrationSlice.actions

export default PartnersRegistrationSlice.reducer