import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IFetchAllAccountManagersAndMarketersResponse, TFilterType} from "../common/types";
import {NONE} from "../../const";
import {INicheFilter} from "../../../../../../common/hooks/queries/filters/useNichesFilterQuery";


export interface IEngineGrowthViewInitialState {
    filterType: TFilterType,
    include: boolean,
    selectedManagers : IFetchAllAccountManagersAndMarketersResponse[],
    marketerHealth: {
        search: string,
        perPage: number,
        page: number,
    },
    largestMovement: {
        search: string,
        perPage: number,
        page: number,
        scaling: boolean
    },
    newestPartners: {
        search: string,
        perPage: number,
        page: number,
    },
    lowestMargins: {
        search: string,
        perPage: number,
        page: number,
    },
    budgetReport: {
        search: string,
        perPage: number,
        page: number,
    },
    engineGMColumnHidden: boolean,
    selectedVerticals: INicheFilter[]
    partnerAccountManagerColumnHidden : boolean,
}

const initialState : IEngineGrowthViewInitialState = {
    filterType: NONE,
    include: true,
    selectedManagers: [],
    selectedVerticals: [],
    engineGMColumnHidden: true,
    marketerHealth: {
        search: '',
        perPage: 5,
        page: 1,
    },
    largestMovement: {
        search: '',
        perPage: 5,
        page: 1,
        scaling: true
    },
    newestPartners: {
        search: '',
        perPage: 5,
        page: 1,
    },
    lowestMargins: {
        search: '',
        perPage: 5,
        page: 1,
    },
    budgetReport: {
        search: '',
        perPage: 10,
        page: 1,
    },
    partnerAccountManagerColumnHidden: true
}

const engineGrowthViewSlice = createSlice({
    name: 'engineGrowthViewSlice',
    initialState,
    reducers: {
        setFilterType: (state : IEngineGrowthViewInitialState, action : PayloadAction<TFilterType>) =>{
            state.filterType = action.payload
        },
        setIncludeExclude: (state: IEngineGrowthViewInitialState, action: PayloadAction<boolean>) => {
            state.include = action.payload
        },
        addOrRemoveFilterManager: (state: IEngineGrowthViewInitialState, action: PayloadAction<IFetchAllAccountManagersAndMarketersResponse>) => {
            if(state.selectedManagers.some(manager => manager.id === action.payload.id)){
                state.selectedManagers = state.selectedManagers.filter(manager => manager.id !== action.payload.id)
            } else {
                state.selectedManagers.push(action.payload)
            }
        },
        addOrRemoveFilterVertical: (state: IEngineGrowthViewInitialState, action: PayloadAction<INicheFilter>) => {
            state.selectedManagers = []
            if(state.selectedVerticals.some(vertical => vertical.id === action.payload.id)){
                state.selectedVerticals = state.selectedVerticals.filter(vertical => vertical.id !== action.payload.id)
            } else {
                state.selectedVerticals.push(action.payload)
            }
        },
        clearSelectedManagers: (state: IEngineGrowthViewInitialState) => {
            state.selectedManagers = []
            state.selectedVerticals = []
            state.filterType = "none"
            state.include = true
        },
        setIsEngineGMColumnHidden: (state: IEngineGrowthViewInitialState, action: PayloadAction<boolean>) => {
            state.engineGMColumnHidden = action.payload
        },
        setMarketerHealthState: (state: IEngineGrowthViewInitialState, action: PayloadAction<{search: string, perPage: number, page: number}>) => {
            state.marketerHealth = action.payload
        },
        setLargestMovementState: (state: IEngineGrowthViewInitialState, action: PayloadAction<{search: string, perPage: number, page: number, scaling: boolean}>) => {
            state.largestMovement = action.payload
        },
        setNewestPartnersState: (state: IEngineGrowthViewInitialState, action: PayloadAction<{search: string, perPage: number, page: number}>) => {
            state.newestPartners = action.payload
        },
        setLowestMarginsState: (state: IEngineGrowthViewInitialState, action: PayloadAction<{search: string, perPage: number, page: number}>) => {
            state.lowestMargins = action.payload
        },
        setBudgetReportState: (state: IEngineGrowthViewInitialState, action: PayloadAction<{search: string, perPage: number, page: number}>) => {
            state.budgetReport = action.payload
        },
        setIsPartnerAccountManagerColumnHidden: (state: IEngineGrowthViewInitialState, action: PayloadAction<boolean>) => {
            state.partnerAccountManagerColumnHidden = action.payload
        },

    }
})

export const {
    setFilterType,
    setIncludeExclude,
    addOrRemoveFilterManager,
    clearSelectedManagers,
    setMarketerHealthState,
    setLargestMovementState,
    setNewestPartnersState,
    setLowestMarginsState,
    setIsEngineGMColumnHidden,
    setBudgetReportState,
    addOrRemoveFilterVertical,
    setIsPartnerAccountManagerColumnHidden
} = engineGrowthViewSlice.actions

export default engineGrowthViewSlice.reducer