import {MutableRefObject, useEffect, useRef, useState} from "react";
import {useAppSelector} from "./useAppSelector";
import {useAppDispatch} from "./useAppDispatch";
import {setTooltipDelay} from "../../store/tooltip.slice";

export const useTooltipDelay = (element: MutableRefObject<HTMLDivElement | null> | null, delay: number) => {
    const {initialDelay} = useAppSelector(state => state.tooltips)

    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const dispatch = useAppDispatch()

    useEffect(() => {
        if(element?.current){

            element.current.onmouseenter = () => {
                dispatch(setTooltipDelay(delay))
                timeoutRef.current  = setTimeout(()=> {
                    dispatch(setTooltipDelay(50))
                }, delay)

            }
            element.current.onmouseleave = () => {
                dispatch(setTooltipDelay(50))
            }
        }

    }, [delay, element, initialDelay])



    return {
        delay,
        initialDelay
    }
}