export const allMarketersPaginationColumns = [
    {
        name: "Marketer ID",
        id: 1,
        checked: true,
        column_key: "id"
    },
    {
        name: "Marketer Company Name",
        id: 2,
        checked: true,
        column_key: "company_name"
    },
    {
        name: "Clicks",
        id: 3,
        checked: true,
        column_key: "clicks"
    },
    {
        name: "Conversions",
        id: 4,
        checked: true,
        column_key: "conversions"
    },
    {
        name: "CVR",
        id: 5,
        checked: true,
        column_key: "cvr"
    },
    {
        name: "Spend Approved",
        id: 6,
        checked: true,
        column_key: "revenue"
    },
    {
        name: "Profit",
        id: 7,
        checked: true,
        column_key: "profit"
    }
];



export const allPartnersPaginationColumns = [
    {
        name: "Partner ID",
        id: 1,
        checked: true,
        column_key: "id"
    },
    {
        name: "Partner Company Name",
        id: 2,
        checked: true,
        column_key: "company_name"
    },
    {
        name: "Partner Name",
        id: 3,
        checked: true,
        column_key: "name"
    },
    {
        name: "Clicks",
        id: 4,
        checked: true,
        column_key: "clicks"
    },
    {
        name: "Conversions",
        id: 5,
        checked: true,
        column_key: "conversions"
    },
    {
        name: "CVR",
        id: 6,
        checked: true,
        column_key: "cvr"
    },
    {
        name: "Spend Approved",
        id: 7,
        checked: true,
        column_key: "revenue"
    },
    {
        name: "Profit",
        id: 8,
        checked: true,
        column_key: "profit"
    }
];

export const allOffersPaginationColumns = [
    {
        name: "Campaign ID",
        id: 1,
        checked: true,
        column_key: "id"
    },
    {
        name: "Campaign Name",
        id: 2,
        checked: true,
        column_key: "name"
    },
    {
        name: "Clicks",
        id: 3,
        checked: true,
        column_key: "clicks"
    },
    {
        name: "Conversions",
        id: 4,
        checked: true,
        column_key: "conversions"
    },
    {
        name: "CVR",
        id: 5,
        checked: true,
        column_key: "cvr"
    },
    {
        name: "Spend Approved",
        id: 6,
        checked: true,
        column_key: "revenue"
    },
    {
        name: "Profit",
        id: 7,
        checked: true,
        column_key: "profit"
    }
];

export const allAccountManagersPaginationColumns = [
    {
        name: "Account Manager",
        id: 1,
        checked: true,
        column_key: "name"
    },
    {
        name: "Clicks",
        id: 2,
        checked: true,
        column_key: "clicks"
    },
    {
        name: "Conversions",
        id: 3,
        checked: true,
        column_key: "conversions"
    },
    {
        name: "CVR",
        id: 4,
        checked: true,
        column_key: "cvr"
    },
    {
        name: "Spend Approved",
        id: 5,
        checked: true,
        column_key: "revenue"
    },
    {
        name: "Profit",
        id: 6,
        checked: true,
        column_key: "profit"
    }
];