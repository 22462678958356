export const removeMacroFromUrl = (url: string, macro: string): string => {
    let newUrl = url
        .replace(macro, '')
        .replaceAll('?&', '?')
        .replaceAll('&&', '&')

    if(newUrl.charAt(newUrl.length - 1) === '&'){
        newUrl = newUrl.slice(0, -1)
    }

    if(newUrl.charAt(newUrl.length - 1) === '?'){
        newUrl = newUrl.slice(0, -1)
    }

    return newUrl

}