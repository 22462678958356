/* Use this function to copy a string with HTML formatting to the clipboard.
    Before using this function, make sure to add allow="clipboard-read; clipboard-write" to the iframe tag in the angular file
 */

const replaceHtmlTags = (input: string): string => {
    return input.replace(/<[/]br>/g, '\n').replace(/&emsp;/g, '    ').replace(/<\/?[^>]+(>|$)/g, "");
}

export async function copyHtmlFormatedString (value: string) {
    const typeRich = 'text/html';
    const blobRich = new Blob([value], { type: typeRich });

    const typePlain = 'text/plain';
    // optionally, replace/remove html tags with plain text equivalents (e.g., newlines instead of <br>)
    const plainText = replaceHtmlTags(value);
    const blobPlain = new Blob([plainText], { type: typePlain });

    // Copy both types so it can be pasted into either a Rich Text editor with formatting,
    // or a plain text editor with basic formatting (e.g., new lines but not HTML tags)
    const data = [new ClipboardItem({ [typeRich]: blobRich, [typePlain]: blobPlain })];

    await navigator.clipboard.write(data);
}