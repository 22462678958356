import {useAppSelector} from "./useAppSelector";
import {useEffect, useState} from "react";

//Checks if logged-in user is admin logged into advertiser account as admin and return boolean
export const useAdvertiserTier = (): boolean => {
    const {user, config} = useAppSelector(state => state.auth)

    const [allowed, setAllowed] = useState(false)


    useEffect(() => {
            if(user && config) {
                console.log("config", config);
                console.log("marketer ui", config.use_marketer_ui);
                console.log("full uslov", (config.user.was_admin && (config.use_marketer_ui !== undefined)));
                if(
                    (config.user.was_admin && (config.use_marketer_ui !== undefined))
                ) {
                    setAllowed(true)

                } else {
                    setAllowed(false)
                }
            }
        }, [
            user,
            config
        ]
    )
    return allowed
}